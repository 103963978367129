import React, { Component, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { I18nRails } from '../../shared/rails-i18n-js';
import * as rh from './helpers/routes';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withLocation } from "../../react-pos/components/withLocation";
import { withParams } from "../../react-pos/components/withParams";
import { orderItemDisplayTitle } from '../models/order_item';
import withParent from './hoc/withParent';
import withCart from './hoc/withCart';
import { resetBasketTotals } from './Model/basket';
import CartService from '../services/CartService';
import withOrder from './hoc/withOrder';


const ShowIndexCart = ({
  Rails,
  clearCart,
  basket,
  increaseQuantity,
  decreaseQuantity,
  ...other
}) => {
  resetBasketTotals(basket, CartService.get(Rails));

  return <>
    {basket.totals.numberOfCartItems < 1 && (

      <p className="center empty-cart">
        <b>{I18nRails.t('client.basket.your_basket_is_empty')}</b>
        <br />
        <b>
          <Link
            style={{ color: 'red' }}
            to={rh.show_location({ location_id: basket.location_id })}
          >
            {I18nRails.t('client.basket.go_back_menus')}
          </Link>
        </b>
      </p>
    )}
    <Grid container>
      <Grid item xs={12}>
        {basket.totals.numberOfCartItems > 0 && (
          <Table className="striped">
            <TableHead>
              <TableRow>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.item')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.price')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.add')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.quantity')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.remove')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.total_price')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {basket.totals.numberOfCartItems > 0 &&
                basket.items.map((order_item, index) => {
                  return (
                    <TableRow key={'hom-oi' + index}>
                      {order_item.type === 'OrderItem' ? (
                        <>
                          <TableCell>{orderItemDisplayTitle(order_item)}</TableCell>
                          <TableCell>
                            {order_item.unit_price || order_item.menu_item.price}
                          </TableCell>
                          <TableCell>
                            <a
                              className="btn-order"
                              onClick={(e) =>
                                increaseQuantity(
                                  order_item,
                                  basket,
                                  e
                                )
                              }
                            >
                              <i className="green-text fas fa-plus-circle"></i>
                            </a>
                          </TableCell>
                          <TableCell>{order_item.weighted_number}</TableCell>
                          <TableCell>
                            <a
                              className="btn-order"
                              onClick={(e) =>
                                decreaseQuantity(
                                  order_item,
                                  basket,
                                  e
                                )
                              }
                            >
                              <i className="red-text fas fa-minus-circle"></i>
                            </a>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>&nbsp;</TableCell>
                        </>
                      )}
                      <TableCell>{order_item.price}</TableCell>
                    </TableRow>
                  );
                })}
              {basket.totals.numberOfCartItems > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.total')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.total, {
                      unit: basket.location.currency_symbol
                    })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.voucher_discount > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.voucher_discount')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.voucher_discount, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.discount > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.discount')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.discount, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.gst > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.tax')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.gst, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.delivery_total > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    <i className="material-icons center subHeadIcons second-color motorcycle"></i>
                    <span>{I18nRails.t('Web.delivery_charges')}</span>
                  </TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.delivery_charges, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {(!basket.totals.delivery_total || !basket.totals.gst || !!basket.totals.discount) && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.grand_total')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.final_price, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Grid>
      <br/>
      <Grid container style={{paddingLeft:'3%',paddingRight:'3%'}}>
        <Grid item xs={6} style={{textAlign: 'left'}}>
          {basket.totals.numberOfCartItems > 0 &&
            <Button className="btn-red" style={{ cursor: 'pointer', paddingTop: '2%', paddingBottom: '2%' }} onClick={(e) => clearCart(basket)}>
              {I18nRails.t('client.basket.empty_basket')}
            </Button>
          }
        </Grid>
        <Grid item xs={6} style={{textAlign: 'right', alignContent: 'flex-end'}}>
          {basket.totals.numberOfCartItems > 0 &&
            <Button className="clr-dark" component={Link} to={rh.location_cart({ location_id: basket.location_id })} style={{ cursor: 'pointer', paddingTop: '2%', paddingBottom: '2%' }}>
              {I18nRails.t('client.basket.checkout')}
            </Button>
          }
        </Grid>
      </Grid>
    </Grid>
  </>;
  // return pug`
  //   if this.state.basket.totals.numberOfCartItems < 1
  //     p.center.empty-cart
  //       b= I18nRails.t('client.basket.your_basket_is_empty')
  //       br
  //       b
  //         Link(style={color:'red'} to=rh.show_location({location_id: this.state.basket.location_id}))= I18nRails.t('client.basket.go_back_menus')
  //   Grid(container)
  //     Grid(item xs=12)
  //       if this.state.basket.totals.numberOfCartItems > 0
  //         Table.striped
  //           TableHead
  //             TableRow
  //               TableCell(style={textTableRowansform: 'capitalize'})= I18nRails.t('client.basket.item')
  //               TableCell(style={textTableRowansform: 'capitalize'})= I18nRails.t('client.basket.price')
  //               TableCell(style={textTableRowansform: 'capitalize'})= I18nRails.t('client.basket.add')
  //               TableCell(style={textTableRowansform: 'capitalize'})= I18nRails.t('client.basket.quantity')
  //               TableCell(style={textTableRowansform: 'capitalize'})= I18nRails.t('client.basket.remove')
  //               TableCell(style={textTableRowansform: 'capitalize'})= I18nRails.t('client.basket.total_price')
  //           TableBody
  //             if this.state.basket.totals.numberOfCartItems > 0
  //               each order_item, index in this.state.basket.items
  //                 TableRow(key='hom-oi'+index)
  //                   if order_item.type == "OrderItem"
  //                     TableCell
  //                       = order_item.displayTitle()
  //                     TableCell ${ order_item.unit_price || order_item.menu_item.price }

  //                     TableCell
  //                       a.btn-order(onClick=(e) => this.props.increaseQuantity(order_item, this.state.basket, e))
  //                         i.green-text.fas.fa-plus-circle
  //                     TableCell ${ order_item.weighted_number }    
  //                     TableCell
  //                       a.btn-order(onClick=(e) => this.props.decreaseQuantity(order_item, this.state.basket, e))
  //                         i.red-text.fas.fa-minus-circle
  //                   else
  //                     TableCell  
  //                     TableCell  
  //                     TableCell  
  //                     TableCell  
  //                   TableCell ${ order_item.price }
  //             if this.state.basket.totals.numberOfCartItems > 0
  //               TableRow
  //                 TableCell  
  //                 TableCell  
  //                 TableCell  
  //                 TableCell  
  //                 TableCell ${ I18nRails.t('Web.total') }
  //                 TableCell ${ I18nRails.numberToCurrency(this.state.basket.totals.total, { unit: this.state.basket.location.currency_symbol }) }
  //               if this.state.basket.totals.voucher_discount > 0
  //                 TableRow
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell ${ I18nRails.t('Web.voucher_discount') }
  //                   TableCell ${ I18nRails.numberToCurrency(this.state.basket.totals.voucher_discount, { unit: this.state.basket.location.currency_symbol }) }
  //               if this.state.basket.totals.discount > 0
  //                 TableRow
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell ${ I18nRails.t('Web.discount') }
  //                   TableCell ${ I18nRails.numberToCurrency(this.state.basket.totals.discount, { unit: this.state.basket.location.currency_symbol }) }
  //               if this.state.basket.totals.gst > 0
  //                 TableRow
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell ${ I18nRails.t('Web.tax') }
  //                   TableCell ${ I18nRails.numberToCurrency(this.state.basket.totals.gst, { unit: this.state.basket.location.currency_symbol }) }
  //               if this.state.basket.totals.delivery_total > 0
  //                 TableRow
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell
  //                     i.material-icons.center.subHeadIcons.second-color motorcycle
  //                     | ${ I18nRails.t('Web.delivery_charges') }
  //                   TableCell ${ I18nRails.numberToCurrency(this.state.basket.totals.delivery_charges, { unit: this.state.basket.location.currency_symbol }) }
  //               if !this.state.basket.totals.delivery_total || !this.state.basket.totals.gst || !!this.state.basket.totals.discount
  //                 TableRow
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell  
  //                   TableCell ${ I18nRails.t('Web.grand_total') }
  //                   TableCell ${ I18nRails.numberToCurrency(this.state.basket.totals.final_price, { unit: this.state.basket.location.currency_symbol }) }
  //     br
  //     Grid(container style={paddingLeft:'3%',paddingRight:'3%'})
  //       Grid(item,xs=6 style={textAlign: 'left'})
  //         if this.state.basket.totals.numberOfCartItems > 0
  //           Button.btn-red( style={ cursor: 'pointer', paddingTop: '2%', paddingBottom: '2%' } onClick=(e) => this.clearCart(this.props.basket))= I18nRails.t('client.basket.empty_basket')
  //       Grid(item,xs=6 style={textAlign: 'right'} text-align="right" align-content= "flex-end")
  //         if this.state.basket.totals.numberOfCartItems > 0
  //           ${ <Button className="clr-dark" component={Link} to={rh.location_cart({ location_id: this.state.basket.location_id })} style={{ cursor: 'pointer', paddingTop: '2%', paddingBottom: '2%' }}> {I18nRails.t('client.basket.checkout')} </Button> }

  // `;
};

export default ShowIndexCart;
