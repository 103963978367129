import React, { Component, useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import QueryString from "query-string";

import { I18nRails } from "../../shared/rails-i18n-js";

import AppLayout from "./AppLayout";

import loginImg from "../../images/Login-small.jpg";
import AuthFactory from "../services/AuthFactory";
import AccessTokenFactory from "../services/AccessTokenFactory";
import sweetalert2 from "sweetalert2";

import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

import * as rh from "./helpers/routes";

import { loadImage } from "./helpers/utils";
import withParent from "./hoc/withParent";


const AppLogin = ({
  Rails,
  showResourceError,
  notifyNative,
  user,
  ...other
}) => {
  const domLocation = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    remember_me: false,
    email: "",
    password: "",
    authenticity_token: "",
  });

  useEffect(() => {
    notifyNative("onNewSignIn");
  }, []);

  const about_us = () => {
    alert(I18nRails.t("display.home.about_us"));
  };

  const validateHandler = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = I18nRails.t("client.profile.login.email_required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = I18nRails.t("client.profile.login.invalid_email_address");
    }

    if (!values.password) {
      errors.password = I18nRails.t("client.profile.login.password_required");
    }

    return errors;
  };

const onSubmit = (values, {
    setSubmitting
  }) => {
    let promise;
    const authFactory = new AuthFactory(Rails);

    promise = authFactory.login(values).then(
      (response) => {
        setSubmitting(false);
        if (response) {
          if (
            !!response.errors ||
            (!!response.data && !!response.data.errors)
          ) {
            const errors =
              response.errors || (!!response.data && !!response.data.errors);

              showResourceError(errors);
            // TODO: show list of errors.
          } else if (response.status != 200) {
            sweetalert2.fire(
              I18nRails.t("client.profile.login.invalid_email_or_pass")
            );
          } else {
            const user = response.data;
            const accessTokenFactory = new AccessTokenFactory(Rails);

            accessTokenFactory.set(user.access_token);

            const query_params = QueryString.parse(domLocation.search);

            if (!!query_params.return_url) {
              navigate(query_params.return_url);
              return;
            } else {
              if (response.headers?.after_sign_in_path &&
                response.headers?.after_sign_in_path !== "/manager/pos" &&
                response.headers?.after_sign_in_path != "/"
              ) {
                  navigate(response.headers.after_sign_in_path)
                  return;
              }
              //else for all
              navigate(
                "/locations?search&city&friends_favorites&open&delivery&ordering&local_region"
              );
            } 
          }
        } else {
          sweetalert2.fire(
            I18nRails.t("client.profile.login.invalid_email_or_pass")
          );
        }
      },
      (error) => {
        sweetalert2.fire(
          I18nRails.t("client.profile.login.invalid_email_or_pass")
        );
        setSubmitting(false);
        // sweetalert2.fire(error.response.data.error);
      }
    );
  };

  const getMetaTags = () => {
    let seo_description = "Personal account login.";
    let seo_title = "My Account Login | Foodnerd";
    let seo_keywords = "Account Login";

    return (
      <Helmet>
        <title>{seo_title}</title>
        <meta name="keywords" content={seo_keywords} />
        <meta name="description" content={seo_description} />
      </Helmet>
    );
  };

  const children = ({ values, errors, touched, isSubmitting, isValidating }) => {
    return (
      <Form className="form-signin login-form" method="post">
        <Link to={rh.show_locations()} style={{display: 'flex', justifyContent: 'center'}}>
          <img className="heading-desc" alt="" src={loadImage('logo-foodnerd-black-100.png')} />
        </Link>
        <Grid item xs={12}>
          <Field className="browser-default form-control login_username" type="email" name="email" placeholder="Email" style={{color: 'red !important'}} />
          <ErrorMessage name="email" component="validation-message" />
          <Field 
            className="browser-default form-control password" 
            type="password" 
            name="password" 
            placeholder="Password" 
            autoComplete="current-password"
          />
          <ErrorMessage name="password" component="validation-message" />
          <input type="hidden" name="authenticity_token" value={values.authenticity_token} />
          <div className="input-field" style={{marginTop: '5%'}}>
            <label>
              <Field className="filled-in checkbox-red" name="remember_me" type="checkbox" defaultChecked={values.remember_me} onChange={(e) => values.remember_me = !values.remember_me} />
              <span htmlFor="remember_me">{I18nRails.t("Web.remember")}</span>
            </label>
          </div>
          <Button className="btn btn-block bt-login btn-second waves-effect waves-light login_btn" style={{width: '100%'}} disabled={Object.keys(errors).length || isSubmitting || isValidating} onSubmit={onSubmit} type="submit">
            {I18nRails.t("client.profile.login.normal")}
          </Button>
          <ErrorMessage name="login" component="validation-message" />
        </Grid>
      </Form>
    );    
  };

  if (
    !values.authenticity_token &&
    typeof document !== "undefined"
  ) {
    let authenticity_token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    setValues({
      ...values,
      authenticity_token: authenticity_token,
    });
  }

  var metaTags = getMetaTags();
  return <>
    { metaTags }
    <AppLayout user={user} Rails={Rails} {...other}>
      <Grid item xs={12} style={{ height: '95vh', marginTop: '3%', backgroundImage: 'url(' + loginImg + ')' }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={10} sm={10} md={4} lg={4}>
            <Card elevation={7} style={{ marginTop: '25%' }} className="login-card-mui">
              <Formik initialValues={values} validate={validateHandler} onSubmit={onSubmit}>
                {children}
              </Formik>
              <form id="facebook" className="form-signin" action="/api/users/auth/facebook" method="post">
                <Grid item xs={12}>
                  <p className="or-txt hide-on-med-and-down">{I18nRails.t("Web.or")}</p>
                  <input type="hidden" name="authenticity_token" value={values.authenticity_token} />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={12}>
                  <div className="input-field login-btn center" style={{ marginBottom: '5%' }}>
                    <Button className="fb-blue white-text waves-effect waves-light login_fb_btn" type="submit">
                      {I18nRails.t("client.profile.login.facebook")}
                    </Button>
                  </div>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <a href="/join" className="btnLogin btn-second" id="register_btn">
                      {I18nRails.t("client.profile.login.new_to_foodnerd")}
                      <span className="largeText">&nbsp;{" "}{I18nRails.t("client.profile.login.join")}&nbsp;</span>
                      <br />
                    </a>
                  </Grid>
                  <Grid item xs={6}>
                    <a href="/forgot_password" className="btnLogin btn-first">
                      {" "}{I18nRails.t("client.profile.login.forgot_password")}
                      <span className="largeText">{I18nRails.t("client.profile.login.click_here")}</span>
                    </a>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>
  </>;
};

export default withParent(AppLogin);
