import React, { useState, useRef } from "react";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams } from "react-router-dom";
import AppLayout from "./AppLayout";
import * as rh from "./helpers/routes";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Hidden from "@mui/material/Hidden";
import { I18nRails } from "../../shared/rails-i18n-js";
import { Helmet } from "react-helmet";
import { JsonLd } from "react-schemaorg";
import {
  getCityKeyValues,
  google_play_app_url_with_fallback,
  itunes_app_url_with_fallback,
  loadImage,
} from "./helpers/utils";
import withParent from "./hoc/withParent";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    height: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const AppMain = ({ 
  Rails, 
  getDefaultSearchCity, 
  user,
  ...other 
}) => {
  const params = useParams();
  const defaultCity = getDefaultSearchCity();
  const city_key_values = useRef(getCityKeyValues());
  const [search, setSearch] = useState("");
  const [city, setCity] = useState(() => {
    return city_key_values.current.find((city) => city.display === defaultCity);
  });
  const [offers, setOffers] = useState();

  const gotoLocations = () => {
    if (!search && !city) {
      return;
    }
    const search_query = [];
    if (!!search) {
      search_query.push(`search_text=${search}`);
    }
    if (!!city) {
      search_query.push(`city=${city.display}`);
    }
    window.location.href = `${window.location.protocol}//${
      window.location.host
    }/locations?${search_query.join("&")}`;
  };

  const selectLocation = (key, values) => {
    let selectedCity = values ? city_key_values.current[values.key] : null;
    setCity(selectedCity);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      return gotoLocations();
    }
  };

  const getMetaTags = () => {
    let seo_description =
      "Foodnerd provides the top food delivery service in Pakistan. Just place your order online & we will deliver to your location. We guarantee food quality!";
    let seo_keywords =
      "Order Food Online, food delivery service, Order Food Online on foodnerd, Food delivery in Pakistan ";
    let seo_title = "Order Food Online - Food Delivery Service | Foodnerd";
    return (
      <Helmet
        script={[
          JsonLd({
            "@context": "https://schema.org",
            "@type": "WebSite",
          }),
        ]}
      >
        <title>{seo_title}</title>
        <meta name="description" content={seo_description} />
        <meta name="keywords" content={seo_keywords} />
      </Helmet>
    );
  };

  const bodyClass = useRef("frontpage-bg");
  const country_alphas = useRef(Rails.country_alphas);
  const anyOfferHasBanner = useRef(undefined);
  var metaTags = getMetaTags();
  return (
    <>
      {metaTags}
      <AppLayout user={user} Rails={Rails} {...other}>
        <div className={useStyles.root}>
          <Grid
            container
            style={{ paddingTop: "40px" }}
            id="intro"
            className="sectionHead hm-gradient"
            spacing={0}
            align="center"
            justify="center"
          >
            <div className="full-bg-img" style={{ width: "100%" }}>
              <Grid item align="center" justify="center" xs={5} sm={5}>
                <img
                  className="landing-logo"
                  alt="Foodnerd Logo"
                  src={loadImage("logo-foodnerd-white.png")}
                />
                <Typography variant="h5" component="h5">
                  <div className="white-text">
                    {I18nRails.t("main.your_citys_best_eats")}
                  </div>
                </Typography>
              </Grid>
              <form onSubmit={gotoLocations}></form>
              <Grid item xs={8} sm={8}>
                <div
                  className="searchGrid"
                  style={{
                    backgroundColor: "white",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "100%",
                    marginTop: "1%",
                    borderRadius: "2px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        onChange={selectLocation}
                        onInputChange={(e) => selectLocation}
                        style={{ width: "100%", padding: "0px" }}
                        id="combo-box-demo-main"
                        placeholder={I18nRails.t("home.city")}
                        options={city_key_values.current}
                        getOptionLabel={(option) => option.value.toString()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={I18nRails.t("City")}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        style={{ width: "100%", padding: "0px" }}
                        variant="outlined"
                        onChange={handleSearchChange}
                        onKeyPress={handleSearchKeyPress}
                        value={search}
                        placeholder={I18nRails.t("home.search_location_dish")}
                        required
                        type="search"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        className="clr-dark"
                        onClick={gotoLocations}
                        style={{
                          marginTop: "5%",
                          color: "white",
                          paddingLeft: "21%",
                          paddingRight: "21%",
                        }}
                      >
                        {I18nRails.t("home.search")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {offers &&
                offers.length > 0 &&
                !!anyOfferHasBanner.current(offers) && (
                  <Grid container spacing={0} align="center" justify="center">
                    <Grid item>
                      <div className="search-field">
                        <marquee>
                          Special offers. Hurry Up! Order food from city's best
                          restaurants is just one click
                        </marquee>
                      </div>
                    </Grid>
                  </Grid>
                )}
            </div>
            {offers &&
              offers.length > 0 &&
              !!anyOfferHasBanner.current(offers) && (
                <Grid container spacing={0} align="center" justify="center">
                  <Grid item>
                    <div className="search-field">
                      <marquee>
                        Special offers. Hurry Up! Order food from city's best
                        restaurants is just one click
                      </marquee>
                    </div>
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: "2%" }}>
            <Grid item sm={12} xs={12} align="center" justify="center">
              <div className="center" id="about">
                <p className="heading-font">
                  {I18nRails.t("main.why_order_with")}
                  <b>
                    <span className="second-color">
                      {" "}
                      {I18nRails.t("main.foodnerd_?")}
                    </span>
                  </b>
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            align="center"
            justify="center"
            style={{ width: "100%", padding: "0 30px" }}
          >
            <Grid item sm={4} xs={12} align="center" justify="center">
              <img
                className="responsive-img"
                alt=""
                src={loadImage("feature1.png")}
              />
              <p className="subhead-font">
                {I18nRails.t("main.your_favorite_restaurants")}
              </p>
              <p className="rgl-font">{I18nRails.t("main.browse_through")}</p>
            </Grid>
            <Grid item sm={4} xs={12} align="center" justify="center">
              <img
                className="responsive-img"
                alt=""
                src={loadImage("feature2.png")}
              />
              <p className="subhead-font">
                {I18nRails.t("main.free_and_easy")}
              </p>
              <p className="rgl-font">
                {I18nRails.t("main.download_app_for_free")}
              </p>
            </Grid>
            <Grid item sm={4} xs={12} align="center" justify="center">
              <img
                className="responsive-img"
                alt=""
                src={loadImage("feature3.png")}
              />
              <p className="subhead-font">
                {I18nRails.t("main.customer_highlights")}
              </p>
              <p className="rgl-font">{I18nRails.t("main.business_partner")}</p>
            </Grid>
            {/* <Grid container className="main-div-ad ad" align="center" justify="center" style={{padding:'20px'}} >
            <a className="ad" href='https://pk.jooble.org/jobs-chef' target='_blank'>
              <Grid container maxWidth="sm" style={{padding:'10px 0'}}>
                <Grid item sm={2} xs={12} align="center" justify="center">
                  <img alt='' src={loadImage('jooble.png')} style={{maxWidth:'100%', height:'auto'}} />
                </Grid>
                <Grid item sm={10} xs={12} style={{paddingLeft:'2%'}}>
                  <h5 className="ad_color no-margin">is one site where you can search jobs across the worldwide Internet.</h5>
                </Grid>
              </Grid>
            </a>
          </Grid>               */}
          </Grid>
          <Grid
            container
            id="restaurant"
            className="clr-down"
            spacing={0}
            align="center"
            justify="center"
            style={{ marginTop: "3%", paddingTop: "3%", paddingBottom: "3%" }}
          >
            <Grid item xs={12} sm={12} align="center" justify="center">
              <img
                className="rest-icon"
                alt=""
                src={loadImage("restaurant-icon.png")}
              />
            </Grid>
            <Grid item xs={12} sm={12} align="center" justify="center">
              <p
                className="heading-font white-text"
                style={{ marginBottom: "0" }}
              >
                {I18nRails.t("main.own_a_restaurant")}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} align="center" justify="center">
              <p className="lead-font white-text">
                {I18nRails.t("main.let_people_discover_you")}
              </p>
              <a href="/business">
                <Button className="white first-color" variant="contained">
                  {I18nRails.t("main.learn_more")}
                </Button>
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            className="sectionDownload"
            style={{ paddingLeft: "50px", paddingRight: "50px" }}
          >
            <Grid item container style={{ float: "left" }}>
              <Container maxWidth="md">
                <Grid item md={12} lg={12} xs={12}>
                  <p style={{ marginBottom: "0", fontSize: "30px" }}>
                    {I18nRails.t("main.download")}
                    <b>
                      <span className="first-color">Foodnerd</span>
                    </b>
                    {I18nRails.t("main.download_app_now")}
                  </p>
                  <br />
                  <Grid item md={12} lg={12} xs={12} maxWidth="sm">
                    <p
                      style={{
                        paddingRight: "5%",
                        marginTop: "0",
                        textAlign: "justify",
                        fontSize: "18px",
                        color: "#4c4b4b",
                        lineHeight: "1.2",
                      }}
                    >
                      {I18nRails.t("main.we_have_all")}
                    </p>
                  </Grid>
                  <br />
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                    justify="center"
                    maxWidth="sm"
                  >
                    <Grid container>
                      <Grid
                        item
                        md={6}
                        lg={6}
                        xs={6}
                        className="apple-download-icon"
                      >
                        <a href={itunes_app_url_with_fallback(Rails)}>
                          <img
                            className="responsive-img"
                            alt="App Store"
                            src={loadImage("apple.png")}
                          />
                        </a>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        lg={6}
                        xs={6}
                        className="google-download-icon"
                      >
                        <a href={google_play_app_url_with_fallback(Rails)}>
                          <img
                            className="responsive-img"
                            alt="Google Play"
                            src={loadImage("googlePlay.png")}
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              align="center"
              justify="center"
              style={{ float: "left" }}
            >
              <Container maxWidth="sm">
                <img
                  className="responsive-img"
                  alt=""
                  src={loadImage("playstore-foodnerd-screen.png")}
                />
              </Container>
            </Grid>
          </Grid>
        </div>
        <Hidden mdDown={true}>
          <Grid container>
            <Grid item md={12} lg={12} xs={12} align="left">
              <img
                className="downloadBack"
                alt=""
                src={loadImage("backGraphics.png")}
              />
            </Grid>
          </Grid>
          <Grid
            container
            align="center"
            justify="center"
            style={{
              backgroundImage:
                "url(" + loadImage("orderNowBackground.jpg") + ")",
              paddingBottom: "5%",
            }}
          >
            <div className="wow fadeIn">
              <p className="heading-font white-text center">
                {I18nRails.t("main.place_order_now")}
              </p>
              <p className="white-text center rgl-font">
                {I18nRails.t("main.just_one_click_away")}
                <br />
              </p>
              <div className="center">
                <Link
                  className="white first-color"
                  to={rh.show_locations()}
                  style={{
                    backgroundColor: "white",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    paddingRight: "4%",
                    paddingLeft: "4%",
                    borderRadius: "3px",
                    marginTop: "3%",
                  }}
                >
                  {I18nRails.t("main.order_now")}
                </Link>
              </div>
            </div>
          </Grid>
        </Hidden>
        <Container>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
              <h2 className="heading-font center">
                {I18nRails.t("main.facts")}
                <b>
                  <span className="first-color"> Foodnerd</span>
                </b>
              </h2>
            </Grid>
            <Grid item xs={12} sm={3} className="grid-divider">
              <div className="col-padding">
                <p className="center text-huge">
                  <span className="counter-value" data-count="3">
                    3
                  </span>
                </p>
                <p className="center">{I18nRails.t("main.countries")}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="col-padding">
                <p className="center text-huge">
                  <span className="counter-value" data-count="3">
                    2
                  </span>
                  K
                </p>
                <p className="center">{I18nRails.t("main.restaurants")}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="col-padding">
                <p className="center text-huge">
                  <span className="counter-value" data-count="4">
                    1
                  </span>
                  M
                </p>
                <p className="center">{I18nRails.t("main.reviews")}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} className="foodies">
              <div className="col-padding">
                <p className="center text-huge">
                  <span className="counter-value" data-count="8">
                    2
                  </span>
                  M
                </p>
                <p className="center">{I18nRails.t("main.foodies")}</p>
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
        </Container>
      </AppLayout>
    </>
  );
};

export default withParent(AppMain);
