import React from "react";
// import { Link } from "react-router-dom";
import { I18nRails } from "../../../shared/rails-i18n-js";
import {price as priceFilter, showPosition as showPositionFilter} from "../../filters";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Zoom from "react-medium-image-zoom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import UserResource from "../../resources/user";
import { isLoggedIn } from "../helpers/utils";
import { menuItemHasRequiredOptions } from "../../models/menu_item";
import { isUserLiked } from "../../models/user";
import { price as priceFilter } from "../../filters";

const template = function({
  Rails,
  user,
  fndLocation,
  menu_item,
  menu,
  index_2,
  cindex,
  openMenuItemModal,
  openReviewModal,
  toggleLike,
  class_for_option_value,
  addToBasketDirect,
}) {
  return (
    <>
      {menu_item.type !== "MenuItemDiv" && menu_item.status !== 'hidden' && (
        <Box border={1} borderColor="#e0e0e0" style={{ backgroundColor: 'white' }}>
          <Grid container spacing={0} style={{ padding: '15px' }}>
            {!!menu_item.url_image1 && (
              <Grid xs={1} item style={{ padding: '5px' }}>
                <img src={menu_item.url_image1} style={{ height: '45px', width: 'auto' }} className='responsive-img' />
              </Grid>
            )}
            {!menu_item.url_image1 && (
              <Grid xs={1} item>
                <h1>{menu}</h1>
              </Grid>
            )}
            <Grid xs={6} item style={{ padding: '10px' }}>
              <span
                className="title"
                onClick={(e) => openMenuItemModal(menu_item, menu)}
                style={{ cursor: 'pointer' }}
              >
                {showPositionFilter(menu_item.order_position, menu.show_position, menu_item.position)}
                {menu_item.title}
              </span>
              <p className="dish-description truncate">{menu_item.description}</p>
              <div className="reviewstats">
                <a
                  className="comments-menu-item left"
                  onClick={(e) => openReviewModal('MenuItem', menu_item, e)}
                >
                  <i className="fas fa-comment left yellow-cmnt" aria-hidden="true"></i>
                  {/* { menu_item.reviews_count > 0 ? menu_item.reviews_count : "" } */}
                </a>
                {isLoggedIn(Rails) ? (
                  <a
                    className="likes-menu-items left"
                    onClick={(e) => toggleLike(menu_item, 'MenuItem', e)}
                  >
                    <i
                      className={`fas fa-heart ${user && isUserLiked(user, menu_item,'MenuItem') ? 'red-text' : ''}`}
                    ></i>
                    {/* { menu_item.favorites_count > 0 ? menu_item.favorites_count : "" } */}
                  </a>
                ) : (
                  <a className="likes-menu-items left" onClick={(e) => toggleLike(menu_item, 'MenuItem', e)}>
                    <i className="fas fa-heart"></i>
                    {/* { menu_item.favorites_count > 0 ? menu_item.favorites_count : "" } */}
                  </a>
                )}
              </div>
            </Grid>
            <Grid xs={5} item style={{textAlign:'right'}}>
              <Grid container>
                {!menuItemHasRequiredOptions(menu_item) &&
                  <Grid item xs={12}>
                    {!!menu_item.price ?
                      <p className="price priceSingle-value right">
                        {priceFilter(menu_item.price, true, fndLocation.currency_symbol)}
                      </p>
                      :
                      !!menu_item.component_option_values && !!menu_item.component_option_values[0] &&
                      <p className="price priceSingle-value right">
                        {priceFilter(menu_item.component_option_values[0].available_option_values[0].price, true, fndLocation.currency_symbol)}
                      </p>
                    }
                  </Grid>
                }
                {menuItemHasRequiredOptions(menu_item) &&
                  <Grid item xs={12}>
                    {menuItemHasRequiredOptions(menu_item) &&
                      !!menu_item.component_option_values &&
                      menu_item.component_option_values.map((component_option_value, index_3) => {
                        if (component_option_value) {
                          return (
                            <div className="price right" key={index_3 + "cov-" + cindex}>
                              {component_option_value.available_option_values.map((available_option_value, index_4) => {
                                return (
                                  <div
                                    className={'price-display'+class_for_option_value(
                                      available_option_value,
                                      menu_item
                                    )}
                                    key={index_4 + "ov-" + cindex}
                                    type="option"
                                  >
                                    <span className="price-value">
                                      {priceFilter(
                                        available_option_value.price,
                                        true,
                                        fndLocation.currency_symbol
                                      )}
                                    </span>
                                    <span className="price-name">{available_option_value.name}</span>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }
                      })}
                  </Grid>
                }
              </Grid>
              <Grid container>
                <Grid xs={12} item>
                  {!menuItemHasRequiredOptions(menu_item) ?
                    <p className="a right order-btn" event='click' onClick={(e) => addToBasketDirect(menu_item, menu)} style={{'cursor':'pointer'}}>
                      {I18nRails.t("client.order_action.create")}
                    </p>
                    :
                    <p className="a right order-btn" event='click' href='#menu-item-modal' onClick={(e) => openMenuItemModal(menu_item, menu, e)} style={{'cursor':'pointer'}}>
                      {I18nRails.t("client.order_action.choose")}
                    </p>
                  }
                </Grid>
              </Grid>
            </Grid>            
          </Grid>
        </Box>
      )}
    </>
  );
  
  return pug`
    if menu_item.type != "MenuItemDiv" && menu_item.status != 'hidden'
      Box(border=1 borderColor="#e0e0e0" style={backgroundColor:'white'})
        Grid(container spacing=0 style={padding:'15px'})
          if !!menu_item.url_image1
            Grid(xs=1 item style={padding:'5px'})
              img(src=menu_item.url_image1, style={height:'45px', width:'auto'}, className='responsive-img')
          if !menu_item.url_image1
            Grid(xs=1 item)
              h1=menu
          Grid(xs=6 item style={padding:'10px'})
            span.title(onClick=(e) => props.component.openMenuItemModal(menu_item, menu), style={cursor: 'pointer'}) ${ showPositionFilter(
    menu_item.order_position,
    menu.show_position,
    menu_item.position
  ) } ${ menu_item.title }
            p.dish-description.truncate ${ menu_item.description }
            .reviewstats
              a.comments-menu-item.left(onClick=(e) => props.component.openReviewModal('MenuItem', menu_item, e))
                i.fas.fa-comment.left.yellow-cmnt(aria-hidden='true')
                // | ${ menu_item.reviews_count > 0 ? menu_item.reviews_count : ""
    }
              if props.component.isLoggedIn()
                a.likes-menu-items.left(onClick=(e) => props.component.toggleLike(menu_item, 'MenuItem', e))
                  i.fas.fa-heart(className= (props.component.state.user && isUserLiked(user, menu_item,'MenuItem')) ? 'red-text' : '')
                  // | ${ menu_item.favorites_count > 0
      ? menu_item.favorites_count
      : ""
    }
              unless props.component.isLoggedIn()
                a.likes-menu-items.left(onClick=(e) => props.component.toggleLike(menu_item, 'MenuItem', e))
                  i.fas.fa-heart
                  // | ${ menu_item.favorites_count > 0
      ? menu_item.favorites_count
      : ""
    }
          Grid(xs=5 item style={textAlign:'right'})
            Grid(container)
              if !menuItemHasRequiredOptions(menu_item)
                Grid(item xs=12)
                  if !!menu_item.price
                    p.price.priceSingle-value.right ${ priceFilter(
      menu_item.price,
      true,
      props.component.state.fndLocation.currency_symbol
    ) }
                  else
                    if !!menu_item.component_option_values && !!menu_item.component_option_values[0]
                      p.price.priceSingle-value.right ${ priceFilter(
      menu_item.component_option_values[0]
        .available_option_values[0].price,
      true,
      props.component.state.fndLocation.currency_symbol
    ) }                                        
              else
                Grid(item xs=12)
                  if menuItemHasRequiredOptions(menu_item)
                    if !!menu_item.component_option_values
                      each component_option_value, index_3 in menu_item.component_option_values
                        if component_option_value
                          .price.right(key=index_3+'cov-'+cindex)
                            each available_option_value, index_4 in component_option_value.available_option_values
                              .price-display(key=index_4+'ov-'+cindex, className=props.component.class_for_option_value(available_option_value, menu_item), type="option")
                                span.price-value ${ priceFilter(
      available_option_value.price,
      true,
      props.component.state.fndLocation.currency_symbol
    ) }
                                span.price-name ${ available_option_value.name }
            Grid(container)
              Grid(xs=12 item)
                if !menuItemHasRequiredOptions(menu_item)
                  p.a.right.order-btn(event='click', onClick= (e) => props.component.addToBasketDirect(menu_item, menu), style={'cursor':'pointer'}) ${ I18nRails.t(
      "client.order_action.create"
    ) }
                else 
                  p.a.right.order-btn(event='click', href='#menu-item-modal', onClick= (e) => props.component.openMenuItemModal(menu_item, menu, e), style={'cursor':'pointer'}) ${ I18nRails.t(
      "client.order_action.choose"
    ) }

  `;
};

export default template;
