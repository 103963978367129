import React, { Component, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import {
  GoogleMapProvider,
  HeatMap,
  InfoWindow,
  MapBox,
  Marker,
  OverlayView,
  Polygon,
} from "@googlemap-react/core";

import Geocode from "./ReverseGeocode";
Geocode.setLanguage("en");
Geocode.setLocationType("APPROXIMATE");

Geocode.setApiKey("AIzaSyCE7p16ERHNQ7MygleUqsCgbJDjrEh_yVM");

const MapLocationPicker = props => {
  const {
    showModal,
    cancelModal
  } = props;

  const [lng, setLng] = useState(73.084488);
  const [lat, setLat] = useState(33.738045);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);

  const setLatLng = () => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        var self = TODO_this;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                if (city) {
                  self.setState({ city });
                }
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                if (state) {
                  self.setState({ state });
                }
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                if (country) {
                  self.setState({ country });
                }
                break;
            }
          }
        }
        self.props.saveMapAddress({ ...self.state });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <div id="testing-modal">
      <Modal
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          width: "100%",
          height: "100vh",
          boxShadow: 24,
          zIndex: 9999,
          right: "auto",
          bottom: "auto",
          padding: "5% 2%",
        }}
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <GoogleMapProvider>
            <MapBox
              apiKey={process.env.GOOGLE_GEOCODE_API_KEY}
              opts={{
                center: {
                  lat: lat,
                  lng: lng,
                },
                zoom: 12,
              }}
              style={{
                height: "70vh",
                width: "70%",
                margin: "0 auto",
              }}
              useDrawing
              useGeometry
              usePlaces
              useVisualization
              onCenterChanged={() => {
                console.log("The center of the map has changed.");
              }}
              onClick={(e) =>
                (setLat(e.latLng.lat()), setLng(e.latLng.lng()))
              }
            />
            <Marker
              id="marker"
              opts={{
                draggable: true,
                label: "",
                position: { lat: lat, lng: lng },
              }}
              onClick={(e) => {
                setLng(e.latLng.lng());
                setLat(e.latLng.lat());
                console.log(JSON.stringify(e));
              }}
            />
          </GoogleMapProvider>
          <div
            style={{
              width: "70%",
              margin: "0 auto",
              background: "#fff",
              padding: "10px",
            }}
          >
            <Button
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                marginRight: "10px",
              }}
              onClick={() => setLatLng()}
            >
              Save
            </Button>
            <Button
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
              onClick={cancelModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MapLocationPicker;
