import React, { useState, useRef, useEffect } from 'react';
import isMobile from 'ismobilejs';
import StickyCart from './StickyCart';
import AppHeader from './Headers/AppHeader';
import AppSideNav from './AppSideNav';
import AppFooter from './Footers/AppFooter';
import Header from './templates/_header';
import SubHeader from './templates/SubHeader';
import LocationTabs from './templates/_location-tabs';
import Maps from './templates/_maps';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { I18nRails } from '../../shared/rails-i18n-js';

const LocationLayout = ({
  Rails,
  user,
  initUser,
  fndLocation,
  fdLocation,
  basket,
  cartService,
  changeQuantity,
  removeBasketItem,
  toggleLike,
  numberOfCartItems,
  selectedCountry,
  changeCountry,
  countryConfig,
  setLatLng,
  children,
  ...others
}) => {
  if(!fndLocation) debugger;
  const [isSideNavOpened, setIsSideNavOpened] = useState(false);
  const [viewHours, setViewHours] = useState(false);

  const handleAppSideNav = () => {
    setIsSideNavOpened(!isSideNavOpened);
  };

  const openHours = () => {
    setViewHours(true);
  };

  const closeHours = () => {
    console.log('close hours');
    setViewHours(false);
  };

  // TODO(react-declassify): refactor this effect (automatically generated from lifecycle)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const isMobileBrowser = (typeof window !== 'undefined') && isMobile(window.navigator.userAgent).any
  if(!fndLocation) debugger;
  return (
    <>
      <AppHeader 
        Rails={Rails}
        handleAppSideNav={handleAppSideNav}
        {...others} 
      />
      <AppSideNav 
        Rails={Rails}
        handleAppSideNav={handleAppSideNav} 
        isSideNavOpened={isSideNavOpened}
        countryConfig={countryConfig}
        selectedCountry={selectedCountry}
        changeCountry={changeCountry}
  
        {...others} 
      />
      {fndLocation ? (
        <Grid container style={{paddingBottom:'5%'}}>
          {isMobileBrowser ? (
            <>
              <Grid xs={12} item>
                <Header 
                  fndLocation={fndLocation}
                  openHours={openHours}
                />
                <SubHeader
                  Rails={Rails}
                  user={user}
                  initUser={initUser}
                  fndLocation={fndLocation}
                  toggleLike={toggleLike}        
                />
                { children }
              </Grid>
              <Grid xs={12} item>
                <StickyCart 
                  className='sticky-cart' 
                  Rails={Rails} 
                  cartService={cartService}
                  basket={basket}
                  changeQuantity={changeQuantity}
                  removeBasketItem={removeBasketItem}                
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid xs={12} item>
                <Header 
                  fndLocation={fndLocation}
                  openHours={openHours}
                />
                <SubHeader
                  Rails={Rails}
                  user={user}
                  initUser={initUser}
                  fndLocation={fndLocation}
                  toggleLike={toggleLike}
                />
              </Grid>
              <Grid xs={8} item style={{backgroundColor:'#F7F7F7',paddingLeft:'3%', paddingRight:'3%'}}>
                <LocationTabs Rails={Rails} fndLocation={fndLocation}/>
                { children }
              </Grid>
              <Grid xs={4} item>
                <StickyCart 
                  className='sticky-cart' 
                  Rails={Rails} {...others}
                  cartService={cartService}
                  basket={basket}
                  changeQuantity={changeQuantity}
                  removeBasketItem={removeBasketItem}                
                />
              </Grid>
            </>
          )}
          <Maps
            fndLocation={fndLocation}
            viewHours={viewHours}
            closeHours={closeHours}
            setLatLng={setLatLng}
            unmappedAddress={null}      
          />            
        </Grid>
      ) : (
        <>
          <CircularProgress style={{marginTop:'8%', marginLeft:'2%'}}/>
          <p style={{marginLeft:'2%'}}>
            { I18nRails.t('Web.loading') + "here" }
          </p>
        </>
      )}
      <AppFooter 
        Rails={Rails}
        fndLocation={fndLocation}
        basket={basket}
        numberOfCartItems={numberOfCartItems}
        countryConfig={countryConfig}              
        {...others} 
      />
    </>
  );
  //   return pug`
  //     AppHeader(isState=isState, handleAppSideNav=handleAppSideNav, paths=paths, ...others)
  //     AppSideNav(...others, handleAppSideNav=handleAppSideNav, isSideNavOpened =state.isSideNavOpened,)
  //     if !!others.fndLocation
  //       Grid(container style={paddingBottom:'5%'})
  //         if isMobileBrowser
  //           Grid(xs=12 item)
  //             Header(...others, component=this)
  //             SubHeader(...others, component=this)
  //             = others.children
  //           Grid(item xs=12)
  //             StickyCart.sticky-cart(...others)
  //         else
  //           Grid(xs=12 item)
  //             Header(...others, component=this)
  //             SubHeader(...others, component=this)
  //           Grid(xs=8 item style={backgroundColor:'#F7F7F7',paddingLeft:'3%', paddingRight:'3%'})
  //             _location_tabs(...others)
  //             = others.children
  //           Grid(item xs=4)
  //             StickyCart.sticky-cart(...others)
  //         _maps(...others, component=this)
  //     else
  //       CircularProgress(style={marginTop:'8%', marginLeft:'2%'})
  //       p(style={marginLeft:'2%'}) ${ I18nRails.t('Web.loading') }
  //     AppFooter(isState=isState, paths=paths, ...others)

  // `;
};

export default LocationLayout;
