import React, { Component, useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { I18nRails } from "../../shared/rails-i18n-js";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import withParent from "./hoc/withParent";

const AddressComponent = ([
  {
    Rails,
    address: propAddress,
    updateAddress,
    addAddress,
    cancelAddress,
    ...other
  },
]) => {
  const [isDenmark, setIsDenmark] = useState(Rails.locale_country === "DK");
  const [isPakistan, setIsPakistan] = useState(Rails.locale_country === "PK");
  const [name_door, setName_door] = useState(propAddress.name_door || "");
  const [street, setStreet] = useState(propAddress.street || "");
  const [street2, setStreet2] = useState(propAddress.street2 || "");
  const [city, setCity] = useState(propAddress.city || "");
  const [zip, setZip] = useState(propAddress.zip || "");
  const [id, setId] = useState(propAddress.id || null);

  return (
    <form>
      <Grid container>
        <Grid xs={12} item style={{ paddingLeft: "4%", paddingRight: "4%" }}>
          <TextField
            label={I18nRails.t("client.progress.addresses.name")}
            style={{ width: "100%" }}
            value={name_door}
            type="text"
            required
            onChange={(e) => setName_door(e.target.value)}
          />
          <TextField
            label={I18nRails.t("client.progress.addresses.street")}
            style={{ width: "100%" }}
            value={street}
            type="text"
            required
            onChange={(e) => setStreet(e.target.value)}
          />
          <TextField
            label={I18nRails.t("client.progress.addresses.street2")}
            style={{ width: "100%" }}
            value={street2}
            type="text"
            onChange={(e) => setStreet2(e.target.value)}
          />
          {!isDenmark && (
            <TextField
              label={I18nRails.t("client.progress.addresses.city")}
              style={{ width: "100%" }}
              value={city}
              type="text"
              required
              onChange={(e) => setCity(e.target.value)}
            />
          )}
          {!isPakistan && (
            <TextField
              label={I18nRails.t("client.progress.addresses.postcode")}
              style={{ width: "100%" }}
              value={zip}
              type="text"
              required
              onChange={(e) => setZip(e.target.value)}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ paddingTop: "3%", paddingLeft: "4%", marginBottom: "2%" }}
      >
        {id && (
          <Grid xs={5} item style={{ textAlign: "left" }}>
            <Button
              variant="contained"
              onClick={(e) =>
                updateAddress(
                  { ...propAddress, name_door, street, street2, city, zip, id },
                  e
                )
              }
              className="btn-second"
            >
              {I18nRails.t("Web.update")}
            </Button>
          </Grid>
        )}
        {!id && (
          <>
            <Grid xs={3} item style={{ textAlight: "left" }}>
              <Button
                className="btn btn-second order-mode-btn right-mard"
                onClick={(e) =>
                  addAddress(
                    {
                      ...propAddress,
                      name_door,
                      street,
                      street2,
                      city,
                      zip,
                      id,
                    },
                    e
                  )
                }
              >
                {I18nRails.t("client.progress.addresses.save")}
              </Button>
            </Grid>
            <Grid xs={3} item style={{ textAlight: "right" }}>
              <Button
                className="btn-first order-mode-btn"
                onClick={(e) => cancelAddress()}
              >
                {I18nRails.t("client.progress.addresses.cancel")}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
  // return pug`
  //   form
  //     Grid(container)
  //       Grid(xs=12 item style={paddingLeft:'4%', paddingRight:'4%'})
  //         TextField(label=I18nRails.t('client.progress.addresses.name') style={width:'100%'}  value=this.state.name_door || '' type="text" required onChange=(e) => this.setState({name_door: e.target.value}))
  //         TextField(label=I18nRails.t('client.progress.addresses.street') style={width:'100%'}  value=this.state.street || ''  type="text" required onChange=(e) => this.setState({street: e.target.value}))
  //         TextField(label=I18nRails.t('client.progress.addresses.street2') style={width:'100%'}   value=this.state.street2 || ''  type="text"  onChange=(e) => this.setState({street2: e.target.value}))
  //         if !this.isDenmark
  //           TextField(label=I18nRails.t('client.progress.addresses.city') style={width:'100%'}  value=this.state.city || ''  type="text" required onChange=(e) => this.setState({city: e.target.value}))
  //         if !this.isPakistan
  //           TextField(label=I18nRails.t('client.progress.addresses.postcode') style={width:'100%'}  value=this.state.zip || ''  type="text" required onChange=(e) => this.setState({zip: e.target.value}))
  //     Grid(container spacing=2 style={paddingTop:'3%', paddingLeft:'4%', marginBottom:'2%'})
  //       if !!this.state.id
  //         Grid(xs=5 item style={textAlign:'left'})
  //           Button(variant="contained" onClick=(e) => this.props.updateAddress(this.state, e)).btn-second ${ I18nRails.t(
  //   "Web.update"
  // ) }
  //       else
  //         Grid(xs=3 item style={textAlight:'left'})
  //           Button.btn.btn-second.order-mode-btn.right-mard(onClick=(e) => this.props.addAddress(this.state, e)) ${ I18nRails.t(
  //   "client.progress.addresses.save"
  // ) }
  //         Grid(xs=3 item style={textAlight:'right'})
  //           Button.btn-first.order-mode-btn(onClick=(e) => this.props.cancelAddress()) ${ I18nRails.t(
  //   "client.progress.addresses.cancel"
  // ) }
  // `;
};

export default withParent(AddressComponent);
