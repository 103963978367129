import React from "react";
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { useRoutes } from "react-router-dom";

import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";


import { CacheProvider } from '@emotion/react';
//import createEmotionServer from '@emotion/server/create-instance';
import createEmotionCache from './createEmotionCache';


import { locationRoutes, siteRoutes } from "../ngreact/routes";

// import { convertCustomRouteConfig, ensureReady } from "../ngreact/rrv4Helpers";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00a65a",
    },
  },
});
const cache = createEmotionCache();

if (typeof window !== "undefined") {
  function Router() {
    const props = JSON.parse(document.getElementById("props").dataset.props); // eslint-disable-line
    var routeConfig;
    if (
      !!props.Rails.domain_place_id &&
      props.Rails.domain_place_type === "Location"
    ) {
      routeConfig = locationRoutes(props);
    } else {
      routeConfig = siteRoutes(props);
    }

    return useRoutes(routeConfig);
  }
  function Main() {
    React.useEffect(() => {
      const jssStyles = document.querySelector("#jss-server-side");
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }, []);


    return (
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    );
  }

  if (document.getElementsByTagName("react-view")[0].innerHTML) {
    const root = hydrateRoot(document.getElementsByTagName("react-view")[0], <Main />);
  } else {
    const root = createRoot(document.getElementsByTagName("react-view")[0]); // createRoot(container!) if you use TypeScript
    root.render(<Main />);
  }
} else {
  // renderSSR(
  //   serverProps.location_fullpath,
  //   serverProps.location_pathname,
  //   serverProps
  // );
}
