import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import QueryString from "query-string";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { I18nRails } from "../../shared/rails-i18n-js";
import AppLayout from "./AppLayout";
import SearchFilters from "./partials/_search_filters";
import InfiniteLocations from "./InfiniteLocations";
import UserResource from "../resources/user";
import LocationResource from "../resources/location";
import InfiniteItems from "./InfiniteItems";
import sweetalert2 from "sweetalert2";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import * as rh from "./helpers/routes";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import { getCityKeyValues, loadImage } from "./helpers/utils";
import withParent from "./hoc/withParent";

const AppLocations = ({
  Rails,
  getDefaultSearchCity,
  fdLocations,
  notifyNative,
  infiniteLocations: propInfiniteLocations,
  setInfiniteLocations,
  geolocateUser,
  initUser,
  initOffers,
  latLng,
  user,
  isLiked,
  toggleLike,
  ...props
}) => {
  const params = useParams();
  const domLocation = useLocation();
  const queryParams = QueryString.parse(domLocation.search);
  const cityKeyValues = useMemo(() => getCityKeyValues(), []);
  const User = UserResource.get(Rails);
  const Location = LocationResource.get(Rails);
  const defaultSearchCity = !!queryParams.city ? queryParams.city : getDefaultSearchCity();
  const [city, setCity] = useState(cityKeyValues.includes(defaultSearchCity) ? cityKeyValues.find((city) => city.display === defaultSearchCity) : "");
  const [isDataFetched, setIsDataFetced] = useState(false);
  const [search, setSearch] = useState(queryParams.search_text || "");
  const [locData, setLocData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  
  const filter = {
    friends_favorites: !!queryParams.local_region ? true : false,
    open_now: !!queryParams.local_region ? true : false,
    has_delivery: !!queryParams.has_delivery ? true : false,
    online_booking: !!queryParams.online_booking ? true : false,
    local_region: !!queryParams.local_region ? true : false,
    city: !!city ? city.display : "",
    search_text: !!search ? search : "",
  };
  const config = {
    page: 0,
    per_page: 21,
    list_size: 1,
    filter,
    locale_country: Rails.locale_country,
  };
  const infiniteLocations = new InfiniteItems(Location, config);


  useEffect(() => {
    if (!!fdLocations) {
      const locations = JSON.parse(fdLocations);

      let result = locations
        .filter((prof) => {
          return prof.name
            .toLowerCase()
            .includes(queryParams?.search_text?.toLowerCase());
        })
        .sort((a, b) => {
          if (
            a.name
              .toLowerCase()
              .indexOf(queryParams?.search_text?.toLowerCase()) >
            b.name
              .toLowerCase()
              .indexOf(queryParams?.search_text?.toLowerCase())
          ) {
            return 1;
          } else if (
            a.name
              .toLowerCase()
              .indexOf(queryParams?.search_text?.toLowerCase()) <
            b.name
              .toLowerCase()
              .indexOf(queryParams?.search_text?.toLowerCase())
          ) {
            return -1;
          } else {
            if (a.name > b.name) return 1;
            else return -1;
          }
        });

      infiniteLocations.addItems(
        result.map((location) => new Location(location))
      );
    }
    setInfiniteLocations(infiniteLocations);
    geolocateUser();
    loadInitial();
    initUser();
    initOffers();

    notifyNative("onShowLocations");
  }, [])

  const loadInitial = async () => {
    if (!!loading) {
      return;
    }
    const filter = infiniteLocations.getFilter();
    try {
      let locations;
      setLoading(true);
      locations = await infiniteLocations.fetchInitialItems(
        filter,
        latLng
      );
      setInfiniteLocations(infiniteLocations);
      return locations;
    } catch (error) {
      return console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreLocations = async () => {
    const { filter, currentPage } = infiniteLocations;
    try {
      let locations;
      setLoading(true);
      locations = await infiniteLocations.fetchMoreItems(
        currentPage,
        filter,
        latLng
      );
      if (locations.length <= 0) {
        setIsDataFetced(true);
      }
      return locations;
    } catch (error) {
      return console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleSearchInputChange = (value) => {
    setSearch(value);
    infiniteLocations.mergeFilter({ search_text: value });
    return loadInitial();
  }

  const setSearchFilters = (filter) => {
    infiniteLocations.mergeFilter(filter);
    return loadInitial();
  };

  const selectLocation = (event, values) => {
    let selectedCity = values ? cityKeyValues[values.key] : null;
    setCity(selectedCity);
    infiniteLocations.mergeFilter({ city: selectedCity?.value });
    return loadInitial();
  };

  const clearSearch = () => {
    setSearch("");
    return infiniteLocations.reset();
  };
  const MetaTags = () => {
    let seo_description =
      "Foodnerd is the best food delivery service in Lahore, Islamabad, and Rawalpindi. These are our main location but we are expanding SOON!";
    let seo_keywords =
      "Delivery Service in Pakistan, Food Delivery service in Lahore, Food Delivery service in Islamabad, Food Delivery service in Rawalpindi";
    let seo_title =
      "Our Locations - Food Delivery Service in Pakistan | Foodnerd";

    return (
      <Helmet>
        <title>{seo_title}</title>
        <meta name="description" content={seo_description} />
        <meta name="keywords" content={seo_keywords} />
      </Helmet>
    );
  };
    
    return (
      <>
        <MetaTags/>
        <Grid container style={{ marginBottom: 30 }}>
          <Grid item xs={12}>
            <AppLayout user={user} Rails={Rails} initOffers={initOffers} {...props}>
              <div
                style={{
                  marginTop: 40,
                  paddingTop: 55,
                  width: "100%",
                  backgroundImage:
                    "url(" + loadImage("locations-banner.png") + ")",
                }}
                className="bd-pageheader"
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={6}>
                    <Typography
                      align="center"
                      variant="h4"
                      className="white-text"
                    >
                      Order Food Online
                    </Typography>

                    <Typography
                      align="center"
                      variant="h5"
                      className="white-text, lead-font"
                    >
                      {I18nRails.t("SearchFood")}
                    </Typography>
                  </Grid>
                </Grid>
                <form onSubmit={loadInitial}>
                  <Card
                    elevation={10}
                    style={{
                      marginLeft: 40,
                      marginTop: 20,
                      marginRight: 40,
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingBottom: 25,
                    }}
                  >
                    <Grid
                      container
                      spacing={3}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={11} sm={11} md={3} lg={3}>
                        <Autocomplete
                          style={{ backgroundColor: "white" }}
                          placeholder={I18nRails.t("City")}
                          value={city}
                          onChange={selectLocation}
                          options={cityKeyValues}
                          //getOptionSelected={(option, value) => option === value}
                          onInputChange={(e) => selectLocation}
                          getOptionLabel={(option) => option?.display || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={I18nRails.t("City")}
                              variant="outlined"
                            ></TextField>
                          )}
                        ></Autocomplete>
                      </Grid>
                      <Grid item xs={11} sm={11} md={6} lg={6}>
                        <TextField
                          style={{ width: "100%" }}
                          id="search-field"
                          variant="outlined"
                          className="field"
                          name={"term"}
                          onChange={(e) =>
                            handleSearchInputChange(e.target.value)
                          }
                          value={search}
                          placeholder={I18nRails.t("SearchText")}
                          required
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={2} md={2}>
                        <Button
                          className="clr-dark white-text"
                          style={{
                            width: "90%",
                            paddingTop: 10,
                            paddingBottom: 10,
                            color: "white",
                          }}
                        >
                          {I18nRails.t("manager.search")}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <SearchFilters
                          setSearchFilters={setSearchFilters}
                          user={user}
                          filter={infiniteLocations.filter}
                        />
                      </Grid>
                    </Grid>

                    <Grid container></Grid>
                  </Card>
                </form>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{
                      padding: "3%",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {I18nRails.t("side_nav.locations")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {city === "" && (
                    <p
                      style={{
                        paddingLeft: "3%",
                        marginLeft: "1rem",
                      }}
                    >
                      Please Select City To View Locations
                    </p>
                  )}
                  {!!city && (
                    <InfiniteLocations
                      isLiked={isLiked}
                      initUser={initUser}
                      toggleLike={toggleLike}
                      items={propInfiniteLocations.items}
                      loadMoreLocations={loadMoreLocations}
                      loading={loading}
                      latLng={latLng}
                      isDataFetched={isDataFetched}
                      Rails={Rails}
                      {...props}
                    />
                  )}
                </Grid>
              </Grid>
            </AppLayout>
          </Grid>
        </Grid>
      </>
    );
}

export default withParent(AppLocations);
