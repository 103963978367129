import React, { Component, useState, useRef, useEffect } from "react";

import ParentComponent from "./ParentComponent";

import LocationLayout from "./LocationLayout";
import Card from "@mui/material/Card";
import { I18nRails } from "../../shared/rails-i18n-js";
import { location_address } from "../models/location";
import withParent from "./hoc/withParent";


const AppLocationAbout = ({
  Rails,
  initLocation,
  setLatLng,
  location,
  basket,
  user,
  cartService,
  changeQuantity,
  ...other
}) => {
  useEffect(() => {
    initLocation();
  })
  return (
    <LocationLayout 
      Rails={Rails}
      fndLocation={location} 
      basket={basket} 
      user={user} 
      setLatLng={setLatLng}
      cartService={cartService}
      changeQuantity={changeQuantity}
      {...other}
    >
      {!!location && (
        <Card elevation={4} style={{padding: '3%'}}>
          <p className="heading-font">{I18nRails.t("Web.about_us")}</p>
          <p>{location.description}</p>
          <div className="divider"></div>
          <p className="subhead-font2">{I18nRails.t("Web.contact_info")}</p>
          <p>
            {I18nRails.t("Web.phone")}{" "}
            <a href={`tel:${location.phone}`}>{location.phone}</a>
            <br />
            {I18nRails.t("Web.address")}: {location_address(location).full_address}
          </p>
        </Card>
      )}
    </LocationLayout>
  );

  // return pug`
  //   LocationLayout(fndLocation=this.state.location, basket=this.state.basket, removeBasketItem=this.removeItem.bind(this), user=this.state.user, ...this.props)
  //     if !!this.state.location
  //       Card(elevation=4 style={padding:'3%'})
  //         p.heading-font ${ I18nRails.t("Web.about_us") }
  //         p= this.state.location.description
  //         .divider
  //         p.subhead-font2 ${ I18nRails.t("Web.contact_info") }
  //         p
  //           | ${ I18nRails.t("Web.phone") } 
  //           a(href="tel:"+this.state.location.phone)= this.state.location.phone
  //           br
  //           | ${ I18nRails.t("Web.address") }: ${ this.state.location.address().full_address
  //   }
  // `;
};

export default withParent(AppLocationAbout);