export const calculateOrderItemPrice = (
  menu_item,
  component_option_value,
  order_item_ingredients
) => {
  let option_price;
  let total_price_for_ingredients = 0;
  if (component_option_value) {
    if (!!component_option_value.price) {
      option_price = component_option_value.price;
    } else {
      option_price = 0;
    }
  } else {
    option_price = menu_item.price;
  }
  //      option_price=if component_option_value then component_option_value.price else menu_item.price
  if (order_item_ingredients) {
    for (let order_item_ingredient of order_item_ingredients) {
      const { menu_item_ingredient } = order_item_ingredient;
      if (!!menu_item_ingredient && !!menu_item_ingredient.delta_price) {
        total_price_for_ingredients += parseFloat(
          order_item_ingredient.number === undefined
            ? menu_item_ingredient.delta_price +
            order_item_ingredient.number
            : menu_item_ingredient.delta_price *
            order_item_ingredient.number
        );
      }
    }
  }
  return total_price_for_ingredients + parseFloat(option_price);
};

export const orderItemOptionValueName = function(orderItem) {
  if (!!orderItem.component_option_value) {
    return (
      orderItem.component_option_value.name ||
      orderItem.component_option_value.option_name
    );
  }
  if (!!orderItem.option_value) {
    return orderItem.option_value.name;
  }
};

export const orderItemOptionValueDescription = function(orderItem) {
  if (!!orderItem.component_option_value) {
    return (
      orderItem.component_option_value.description ||
      orderItem.component_option_value.option_name
    );
  }
  if (!!orderItem.option_value) {
    return orderItem.option_value.description;
  }
};

export const orderItemDisplayTitle = function(orderItem) {
  if (!!orderItemOptionValueName(orderItem)) {
    return `${ orderItem.menu_item.title }, ${ orderItemOptionValueName(orderItem) }`;
  } else {
    return orderItem.menu_item.title;
  }
};

export const orderItemDisplayDescription = function(orderItem) {
  if (!!orderItemOptionValueDescription(orderItem)) {
    return `${ orderItem.menu_item.description
      }, ${ orderItemOptionValueDescription(orderItem) }`;
  } else {
    return orderItem.menu_item.description;
  }
};

export const orderItemMatchesSelection = function(
  orderItem,
  menu_item,
  component_option_value,
  order_item_ingredients
) {
  if (orderItem.item_id === menu_item.id && orderItem.item_type === 'MenuItem') {
    let component_option_value_id;
    if (!!component_option_value) {
      if (!orderItem.component_option_value_id) {
        return false;
      }
      component_option_value_id =
        component_option_value.component_option_value_id ||
        component_option_value.id;
      if (component_option_value_id !== orderItem.component_option_value_id) {
        return false;
      }
    } else {
      if (!!orderItem.component_option_value_id) {
        return false;
      } //exists in orderItem order item, but not present in the selection
    }

    if (!!order_item_ingredients) {
      if (!orderItem.order_item_ingredients) {
        return false;
      }
      if (
        order_item_ingredients.length !== orderItem.order_item_ingredients.length
      ) {
        return false;
      }
    } else {
      if (!!orderItem.order_item_ingredients) {
        return false;
      } //exists in orderItem order item, but not present in the selection
    }

    let match_found = true;
    for (let order_item_ingredient of order_item_ingredients) {
      match_found = false;
      //passed order_item_ingredients array can be a list of order_item_ingredients or menu_item_ingredients
      const menu_item_ingredient_id =
        order_item_ingredient.menu_item_ingredient_id ||
        order_item_ingredient.id;
      for (let order_item_ing of orderItem.order_item_ingredients) {
        if (
          order_item_ing.menu_item_ingredient_id === menu_item_ingredient_id
        ) {
          match_found = true;
          break;
        }
      }
      if (!match_found) {
        return false;
      }
    } //match in loop did not succeed
    return match_found; //all matches succeeded
  }

  return false;
};

export const orderItemAdjustQuantity = function(orderItem, qty) {
  orderItem.number += 1;
  if (orderItem.item_type === 'MenuItem') {
    orderItem.price =
      calculateOrderItemPrice(
        orderItem.item,
        orderItem.component_option_value,
        orderItem.order_item_ingredients
      ) * orderItem.number;
  } else {
    orderItem.price = orderItem.item.price * orderItem.number;
  }
  return orderItem.number;
};

export const orderItemDisplayPrice = function(orderItem) {
  let total_price_for_ingredients = 0;
  const option_price = orderItem.component_option_value
    ? parseFloat(orderItem.component_option_value.price)
    : orderItem.basePrice();
  if (orderItem.order_item_ingredients) {
    for (let ingredient of orderItem.order_item_ingredients) {
      total_price_for_ingredients += parseFloat(ingredient.delta_price);
    }
  }

  return total_price_for_ingredients + option_price;
};