import React, { Component, useState, useRef, useEffect } from "react";
import ParentComponent from "./ParentComponent";
import FavoriteLocations from "./FavoriteLocations";
import FavoriteMenuItems from "./FavoriteMenuItems";
import AppLayout from "./AppLayout";
import FavoriteResource from "../resources/favorite";
import FavItemResource from "../resources/favoriteMenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteItems from "./InfiniteItems";
import { I18nRails } from "../../shared/rails-i18n-js";
import UserResource from "../resources/user";
import withParent from "./hoc/withParent";

const AppFavorites = ({
  Rails,
  initUser,
  user,
  setUser,
  geolocateUser,
  isLiked,
  ...other
}) => {

  const config = {
    page: 0,
    per_page: 21,
    list_size: 1,
    locale_country: Rails.locale_country,
  };

  const [favorites, setFavorites] = useState([]);
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [tab_value, setTab_value] = useState(0);
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [loadingFavoriteItems, setLoadingFavoriteItems] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState();

  const FavoriteItemsRef = useRef(FavItemResource.get(Rails));
  const FavoriteRef = useRef(FavoriteResource.get(Rails));
  const infiniteFavoriteItemsRef = useRef(new InfiniteItems(FavoriteItemsRef.current, config));


  useEffect(() => {
    loadInitial();
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", onDocumentScroll);
    }

    // const favitems = JSON.parse(FavoriteItems);
    // console.log('thisfavoriteitems', FavoriteItems)
    // this.infiniteLocations.addItems(favitems.map(FavoriteItem => new FavoriteItems(FavoriteItem)));

    if (!user) {
      initUser((user) => {
        setUser(user);
        geolocateUser(() => {
          FavoriteRef.current.query({}, (favorites) => {
            setLoadingFavorites(false)
            setFavorites(favorites);
          });
        });
      });
    } else {
      geolocateUser(() => {
        FavoriteRef.current.query({}, (favorites) => {
          setLoadingFavorites(false)
          setFavorites(favorites);
        });
      });
    } 
    return () => {
      if (typeof window !== "undefined") {
        document.removeEventListener("scroll", onDocumentScroll);
      }
    }
  }, []);


  const loadMoreFavoriteItems = () => {
    const { filter } = infiniteFavoriteItemsRef.current;
    const { currentPage } = infiniteFavoriteItemsRef.current;

    setLoadingMore(true);
    return infiniteFavoriteItemsRef.current.fetchMoreItems(
      currentPage,
      filter
    ).catch(error => {
      console.error(error);

    }).finally(() => {
      setLoadingMore(false);        
    })
  }

  const onDocumentScroll = (e) => {
    const documentElement = document.documentElement
    const scrollTop =
      (window.scrollY || documentElement.scrollTop) -
      (documentElement.clientTop || 0);
    let condition =
      scrollTop + documentElement.clientHeight + 900 >=
      documentElement.scrollHeight;
    condition = condition && !loadingMore;
    if (condition && tab_value == 1) {
      setLoadingMore(false);
      loadMoreFavoriteItems();
    }
  };

  const loadInitial = () => {
    if (!!initialLoad) {
      return;
    }
    const filter = infiniteFavoriteItemsRef.current.getFilter();
    setLoading(true);
    setInitialLoad(true);
    infiniteFavoriteItemsRef.current.fetchInitialItems(
      filter
    ).finally(() => {
      setLoading(false);
      setInitialLoad(false);  
    }).catch(error => {
      console.error(error);
    });
  };

  const favoriteLocations = () => {
    return favorites;
  };

  const favoriteMenuItems = () => {
    return favoriteItems;
  };

  const setTabValue = (e, val) => {
    setTab_value(val);
  };

  const toggleFavoriteLike = (likeable, likeable_type, event) => {
    event.preventDefault();
    event.stopPropagation();
    const User = UserResource.get(Rails);
    let userObject = new User(user);

    const filteredItems = [];

    favorites.forEach((item, index) => {
      if (item.location_id !== likeable.id) {
        filteredItems.push(item);
      }
    });

    setFavorites(filteredItems);

    likeable.red = false;
    setUserUnlike(userObject, likeable, likeable_type);
  }

  const items = infiniteFavoriteItemsRef.current.items;
  return (
    <AppLayout Rails={Rails} user={user} {...other}>
      <Grid container>
        <Grid item xs={12}>
          <div style={{padding: '12px 0px', width: '100%', marginTop: '5%'}}>
            <Tabs textColor='secondary' value={tab_value} onChange={setTabValue} indicatorColor='secondary' style={{backgroundColor: '#F7F7F7', paddingTop: '24px'}}>
              <Tab label={I18nRails.t("favoritetabs.places")} style={{backgroundColor: '#F7F7F7'}}/>
              <Tab label={I18nRails.t("favoritetabs.dishes")} style={{backgroundColor: '#F7F7F7'}}/>
            </Tabs>
            <TabPanel value={tab_value} index={0}>
              {loadingFavorites ? (
                <div style={{padding: '3%'}}>
                  <CircularProgress/>
                  <p>{I18nRails.t("Web.loading")}</p>
                </div>
              ) : !loadingFavorites && favorites.length == 0 ? (
                <div style={{backgroundColor: '#F50057', padding: '1.5%', margin: '4%', borderRadius: '10px'}}>
                  <p className='white-text'>No Favorite Locations</p>
                </div>
              ) : (
                <FavoriteLocations favorites={favoriteLocations()} isLiked={isLiked} toggleFavoriteLike={toggleFavoriteLike} {...other}/>
              )}
            </TabPanel>
            <TabPanel value={tab_value} index={1}>
              {loadingFavoriteItems ? (
                <div style={{padding: '3%'}}>
                  <CircularProgress/>
                  <p>{I18nRails.t("Web.loading")}</p>
                </div>
              ) : items.length == 0 ? (
                <div style={{backgroundColor: '#F50057', padding: '1.5%', margin: '4%', borderRadius: '10px'}}>
                  <p className='white-text'>No Favorite Items</p>
                </div>
              ) : (
                <FavoriteMenuItems favorites={items} {...other}/>
              )}
              <Grid container>
                {loadingMore && (
                  <CircularProgress color="primary" style={{marginLeft: '3%', marginBottom: '1%'}}/>
                )}
                <p style={{marginLeft: '3%', marginBottom: '5%'}}>Loading more..</p>
              </Grid>
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </AppLayout>
  );
  //   return pug`
  //   AppLayout(user=this.state.user, ...this.props)
  //     Grid(container)
  //       Grid(item xs=12)
  //         div(style={padding:'12px 0px', width:'100%',marginTop:'5%'})
  //           Tabs(textColor='secondary' value=this.state.tab_value onChange=this.setTabValue indicatorColor='secondary' style={backgroundColor:'#F7F7F7', paddingTop:'24px'})
  //             Tab(label=${ I18nRails.t("favoritetabs.places") } style={backgroundColor:'#F7F7F7'})
  //             Tab(label=${ I18nRails.t("favoritetabs.dishes") } style={backgroundColor:'#F7F7F7'})
  //           TabPanel(value=this.state.tab_value index=0)
  //             if this.state.loadingFavorites
  //               div(style={padding:'3%'}) 
  //                 CircularProgress()
  //                 p ${ I18nRails.t("Web.loading") }
  //             else if !this.state.loadingFavorites
  //               if this.state.favorites.length == 0
  //                 div(style={backgroundColor:'#F50057', padding:'1.5%', margin:'4%', borderRadius:'10px'})  
  //                   p.white-text No Favorite Locations
  //               else

  //                 FavoriteLocations(favorites=this.favoriteLocations(), isLiked=this.isLiked, toggleFavoriteLike=this.toggleFavoriteLike, ...this.props)
  //           TabPanel(value=this.state.tab_value index=1)
  //             if this.state.loadingFavoriteItems 
  //               div(style={padding:'3%'}) 
  //                 CircularProgress()
  //                 p ${ I18nRails.t("Web.loading") }
  //             else
  //               if items.length == 0
  //                 div(style={backgroundColor:'#F50057', padding:'1.5%', margin:'4%', borderRadius:'10px'})  
  //                   p.white-text No Favorite Items
  //               else
  //                 FavoriteMenuItems(favorites=items, ...this.props)
  //                   Grid(container)
  //             if this.state.loadingMore
  //               CircularProgress(color="primary" style={marginLeft:'3%', marginBottom:'1%'})
  //               p(style={marginLeft:'3%', marginBottom:'5%'}) Loading more..
  // `;
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && children}</div>;
}

export default withParent(AppFavorites);