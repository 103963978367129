import React from "react";
import { Link } from "react-router-dom";
import { I18nRails } from "../../shared/rails-i18n-js";
import ParentComponent from "./ParentComponent";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { getLocationDistance, location_address, location_card_logo_url, location_main_image_url } from "../models/location";

export default class LocationCard extends React.Component {
  constructor(props) {
    super(props);
  }

  getDistance() {
    if (!!this.props.latLng) {
      return getLocationDistance(this.props.fndLocation, this.props.latLng);
    }
    // if !!@state.user && fdLocations.length > 0
    //   if !!@state.user.provider
    //     fdLocation_ids = fdLocations.map (l) => l.id
    //     me = @
    //     @FavoriteService.fdLocations_friends_favorites(fdLocation_ids.join(',')).then (friends_favorites) =>
    //       me.user.setFriendsFavorites(friends_favorites.data)
  }

  getLatestSchedule(schedules) {
    var maxID = Math.max.apply(
      Math,
      schedules.map(function(schedule) {
        return schedule.id;
      })
    );
    var latestSchedule = schedules.find((schedule) => schedule.id == maxID);

    return latestSchedule.text != null ? latestSchedule.text : "";
  }
  render() {
    const fdLocation = this.props.fndLocation;
    const { latLng } = this.props;
    return (
      <Link to={'/locations/' + fdLocation.slug}>
        <>
          {fdLocation.schedules &&
            fdLocation.schedules.length > 0 &&
            this.getLatestSchedule(fdLocation.schedules) != "" ? (
            <div className="box">
              <div id="ribbon-top-right" className="ribbon ribbon-top-right">
                <span>{this.getLatestSchedule(fdLocation.schedules)}</span>
              </div>
            </div>
          ) : null}
          <Card id="locationCard" elevation={4} style={{ height: 370 }}>
            <CardMedia>
              <img
                style={{ height: "215px", width: "100%", objectFit: "cover" }}
                src={location_main_image_url(fdLocation)}
              />
            </CardMedia>

            <CardHeader
              avatar={
                <Avatar alt="Cindy Baker" src={location_card_logo_url(fdLocation)} />
              }
              action={
                <>
                  <Tooltip title="Verified Location">
                    <IconButton style={{ cursor: "default" }}>
                      <VerifiedUserIcon style={{ color: "#65d865" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Favorite">
                    <IconButton
                      disableFocusRipple
                      style={{
                        paddingRight: 0,
                        "&:hover": { backgroundColor: "#FFF" },
                      }}
                      onClick={(e) =>
                        this.props.inFavoriteRoute
                          ? this.props.toggleFavoriteLike(
                            fdLocation,
                            "Location",
                            e
                          )
                          : this.props.toggleLike(fdLocation, "Location", e)
                      }
                      aria-label="settings"
                    >
                      <FavoriteIcon
                        style={{
                          color:
                            (this.props.user &&
                              this.props.isLiked(fdLocation, "Location").red) ||
                              fdLocation.red
                              ? "red"
                              : "grey",
                        }}
                      />
                      <span style={{ fontSize: 14 }}>
                        {fdLocation.favorites_count}
                      </span>
                    </IconButton>
                  </Tooltip>
                </>
              }
              subheader={fdLocation.name}
            >
              <img
                style={{ height: "100px", width: "100px" }}
                src={location_main_image_url(fdLocation)}
              />
            </CardHeader>

            <CardContent>
              <Grid container>
                <Grid xs={12}>
                  {!!fdLocation.addresses && fdLocation.addresses.length > 0 ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {location_address(fdLocation).local_address}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid xs={3}>
                  {fdLocation.deliveries && fdLocation.deliveries.length > 0 ? (
                    <Chip label="Delivery" icon={<DoneIcon />} />
                  ) : null}
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={3}>
                  {fdLocation.online_booking == "active" ? (
                    <Chip
                      label="Online"
                      style={{ marginleft: 15 }}
                      icon={<DoneIcon />}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      </Link>
    );
    // return pug`
    // Link(to = '/locations/' + fdLocation.slug)
    //   ${ (
    //     <>
    //       {fdLocation.schedules &&
    //         fdLocation.schedules.length > 0 &&
    //         this.getLatestSchedule(fdLocation.schedules) != "" ? (
    //         <div className="box">
    //           <div id="ribbon-top-right" className="ribbon ribbon-top-right">
    //             <span>{this.getLatestSchedule(fdLocation.schedules)}</span>
    //           </div>
    //         </div>
    //       ) : null}



    //       <Card id="locationCard" elevation={4} style={{ height: 370 }}>
    //         <CardMedia>
    //           <img
    //             style={{ height: "215px", width: "100%", objectFit: "cover" }}
    //             src={fdLocation.main_image_url()}
    //           />
    //         </CardMedia>

    //         <CardHeader
    //           avatar={
    //             <Avatar alt="Cindy Baker" src={fdLocation.card_logo_url()} />
    //           }
    //           action={
    //             <>
    //               <Tooltip title="Verified Location">
    //                 <IconButton style={{ cursor: "default" }}>
    //                   <VerifiedUserIcon style={{ color: "#65d865" }} />
    //                 </IconButton>
    //               </Tooltip>
    //               <Tooltip title="Favorite">
    //                 <IconButton
    //                   disableFocusRipple
    //                   style={{
    //                     paddingRight: 0,
    //                     "&:hover": { backgroundColor: "#FFF" },
    //                   }}
    //                   onClick={(e) =>
    //                     this.props.inFavoriteRoute
    //                       ? this.props.toggleFavoriteLike(
    //                         fdLocation,
    //                         "Location",
    //                         e
    //                       )
    //                       : this.props.toggleLike(fdLocation, "Location", e)
    //                   }
    //                   aria-label="settings"
    //                 >
    //                   <FavoriteIcon
    //                     style={{
    //                       color:
    //                         (this.props.user &&
    //                           this.props.isLiked(fdLocation, "Location").red) ||
    //                           fdLocation.red
    //                           ? "red"
    //                           : "grey",
    //                     }}
    //                   />
    //                   <span style={{ fontSize: 14 }}>
    //                     {fdLocation.favorites_count}
    //                   </span>
    //                 </IconButton>
    //               </Tooltip>
    //             </>
    //           }
    //           subheader={fdLocation.name}
    //         >
    //           <img
    //             style={{ height: "100px", width: "100px" }}
    //             src={fdLocation.main_image_url()}
    //           />
    //         </CardHeader>

    //         <CardContent>
    //           <Grid container>
    //             <Grid xs={12}>
    //               {!!fdLocation.addresses && fdLocation.addresses.length > 0 ? (
    //                 <Typography
    //                   variant="body2"
    //                   color="textSecondary"
    //                   component="p"
    //                 >
    //                   {fdLocation.address().local_address}
    //                 </Typography>
    //               ) : null}
    //             </Grid>
    //             <Grid xs={3}>
    //               {fdLocation.deliveries && fdLocation.deliveries.length > 0 ? (
    //                 <Chip label="Delivery" icon={<DoneIcon />} />
    //               ) : null}
    //             </Grid>
    //             <Grid xs={1}></Grid>
    //             <Grid xs={3}>
    //               {fdLocation.online_booking == "active" ? (
    //                 <Chip
    //                   label="Online"
    //                   style={{ marginleft: 15 }}
    //                   icon={<DoneIcon />}
    //                 />
    //               ) : null}
    //             </Grid>
    //           </Grid>
    //         </CardContent>
    //       </Card>
    //     </>
    //   ) }
    // `;
  }
}

