/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component, useState, useRef, useEffect } from 'react';


import AppLayout from './AppLayout';

import _updates_filters from './templates/_updates_filters';
import _updates from './templates/_updates';
import withParent from './hoc/withParent';


const AppUpdates = ({
  Rails,
  user,
  initUser,
  setUser,
  setLatLng,
  ...others
}) => {
  const [locations0, setLocations] = useState([]);
  const [favorites, setFavorites] = useState();
  const User = useRef(undefined);
  const FavoritesFactory = useRef(undefined);
  const favorites0 = useRef(undefined);
  const locations1 = useRef(undefined);
  const GeolocationFactory = useRef(undefined);

  useEffect(() => {
    initUser(user => {
      setUser(user);
      FavoritesFactory.current.query({},
        favorites => {
          let locations = locations0;
          let i = 0;
          while (i < favorites0.current.length) {
            if (favorites0.current[i].obj.highlights.length > 0) {
              locations1.current.push(favorites0.current[i].obj);
            }
          }
          setFavorites(favorites);
          setLocations(locations);
        });

      GeolocationFactory.current.get(function(latLng) {
        setLatLng(latLng);
      });
    });
  }, []);

  return (
    <AppLayout Rails={Rails} user={user} {...others}>
      <main>
        <div className="content-area">
          <aside id="left-sidebar-nav" />
          <section id="content">
            <h2 className="updates-heading">Updates</h2>
            <UpdatesFilters component={TODO_this} />
            <Updates component={TODO_this} />
          </section>
        </div>
      </main>
    </AppLayout>
  );
  // return pug`
  //   AppLayout(user=this.state.user, ...this.props)
  //     main
  //       .content-area
  //         aside#left-sidebar-nav
  //         section#content
  //           .updates-heading Updates
  //           _updates_filters(component=this)
  //           _updates(component=this)
  // `;
};

export default withParent(AppUpdates);
