/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import {Redirect} from 'react-router';

export default class AppReload extends React.Component {
  constructor(props) {
    super(props);
    window.location.href = props.match.url
  }
}
