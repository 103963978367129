import React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { I18nRails } from "../../../shared/rails-i18n-js";

import _checkout_state_register from "./_checkout_state_register";
import AddressCheckoutState from "./_checkout_state_address";
// import _checkout_state_delivery_time from "./_checkout_state_delivery-time";
import _checkout_state_confirm_pin from "./_checkout_state_confirm-pin";
import _checkout_state_payment from "./_checkout_state_payment";
import _checkout_state_radios_payment from "./_checkout_state_radios_payment";

import ShowCart from "./ShowCart";

import TextField from "@mui/material/TextField";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PlaceIcon from "@mui/icons-material/Place";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PaymentIcon from "@mui/icons-material/Payment";
import DescriptionIcon from "@mui/icons-material/Description";

import Button from "@mui/material/Button";
import { isLoggedIn } from "../helpers/utils";

export default function CheckoutSteps({
  Rails,
  errorMessage,
  order_state,
  gotoCheckoutState,
  basket,
  channel,
  selectedDate,
  handleDateChange,
  order,
  requirePinCode,
  selectChannel,
  requirePhone,
  setBasketField,
  verifyVoucherCode,
  changeQuantity,
  clearCart,
  proceedToCheckout,
  user,
  showVerification,
  openGuestRegisterPasswordDialog,
  showAddressError,
  addressError,
  showCreatedUserAlert,
  handleClose,
  userInputVerification,
  addVerification,
  invalidVerificationCodeError,
  verifyOrder,
  createCustomer,
  onChangePhoneNumberEvent,
  confirmPhoneNumber,
  onChangePinEvent,
  pinCode,
  validatePinCode,
  setUserPhone,
  radioValue,
  orderProcessing,
  paypalProps,
  placeOrder,
  onRadioChange,
  selectedAddress,
  selectAddress,
  cancelChannel,
}) {
  return (
    <>
      {!!errorMessage && (
        <p className="center empty-cart">
          <b>{errorMessage}</b>
        </p>
      )}
      <Accordion className="cart-state" expanded={order_state === "cart"}>
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("cart")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ShoppingCartIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.your_order")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className="column-flex"
          onClick={(e) => e.stopPropagation()}
        >
          <ShowCart
            hideCheckout={false}
            basket={basket}
            channel={channel}
            setBasketField={setBasketField}
            verifyVoucherCode={verifyVoucherCode}
            changeQuantity={changeQuantity}
            clearCart={clearCart}
            proceedToCheckout={proceedToCheckout}
            order={order}
          />
        </AccordionDetails>
      </Accordion>
      {!isLoggedIn(Rails) && (
        <Accordion className="cart-state" expanded={order_state === "register"}>
          <AccordionSummary
            onClick={(e) => gotoCheckoutState("register")}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <span style={{ display: "flex", alignItems: "center" }}>
                <AccountBoxIcon />
                <span style={{ marginLeft: "16px" }}>
                  {I18nRails.t("client.basket.your_order")}
                </span>
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="column-flex"
            onClick={(e) => e.stopPropagation()}
          >
            <_checkout_state_register
              showVerification={showVerification}
              openGuestRegisterPasswordDialog={openGuestRegisterPasswordDialog}
              channel={channel}
              showAddressError={showAddressError}
              addressError={addressError}
              showCreatedUserAlert={showCreatedUserAlert}
              handleClose={handleClose}
              userInputVerification={userInputVerification}
              addVerification={addVerification}
              invalidVerificationCodeError={invalidVerificationCodeError}
              verifyOrder={verifyOrder}
              createCustomer={createCustomer}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        className="cart-state"
        disabled={basket.totals.numberOfCartItems < 1 ? true : false}
        expanded={order_state === "channel"}
      >
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("channel")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ShoppingBasketIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.checkout_mode")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className="column-flex"
          onClick={(e) => e.stopPropagation()}
        >
          {basket.items.length > 0 && (
            <>
              <p className="choose-channel">
                {I18nRails.t("Web.ordering.choose_channel")}
              </p>
              <div className="checkoutDetail">
                {basket.location &&
                  basket.location.channels &&
                  basket.location.channels.map((channel, index) => (
                    <Button
                      className="clr-dark"
                      style={{ marginRight: "1%" }}
                      key={index}
                      onClick={(e) => selectChannel(channel.name)}
                    >
                      {channel.name}
                    </Button>
                  ))}
              </div>
            </>
          )}
          {!!selectedDate && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                ampm
                label="Want Order At"
                value={selectedDate}
                onChange={handleDateChange}
                disablePast
                format="yyyy/MM/dd HH:mm a"
                className="mui-picket-custom-width"
              />
            </LocalizationProvider>
          )}
        </AccordionDetails>
      </Accordion>
      {channel && channel === "delivery" && (
        <Accordion
          disabled={basket.totals.numberOfCartItems < 1 ? true : false}
          className={
            "cart-state" + (channel === "delivery" && user ? "" : "hide")
          }
          expanded={order_state == "address"}
        >
          <AccordionSummary
            onClick={(e) => gotoCheckoutState("address")}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <span style={{ display: "flex", alignItems: "center" }}>
                <PlaceIcon />
                <span style={{ marginLeft: "16px" }}>
                  {I18nRails.t("client.basket.delivery_address")}
                </span>
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="column-flex">
            <AddressCheckoutState
              Rails={Rails}
              basket={basket}
              user={user}
              selectAddress={selectAddress}
              cancelChannel={cancelChannel}
              selectedAddress={selectedAddress}
              unmappedAddress={unmappedAddress}
              showUpdatedAddressAlert={showUpdatedAddressAlert}
              guestStreet1={guestStreet1}
              guestStreet2={guestStreet2}
              guestCity={guestCity}
              guestZip={guestZip}
              setGuestStreet1={setGuestStreet1}
              setGuestStreet2={setGuestStreet2}
              setGuestCity={setGuestCity}
              setGuestZip={setGuestZip}
              updateCustomerAddress={updateCustomerAddress}
              reselectAddress={reselectAddress}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {user && user.token_status === "set" && (
        <Accordion
          disabled={basket.totals.numberOfCartItems < 1 ? true : false}
          className={
            "cart-state" + (requirePinCode() || requirePhone()) ? "" : "hide"
          }
          expanded={order_state === "confirm-pin"}
        >
          <AccordionSummary
            onClick={(e) => gotoCheckoutState("confirm-pin")}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <span style={{ display: "flex", alignItems: "center" }}>
                <VerifiedUserIcon />
                <span style={{ marginLeft: "16px" }}>
                  {I18nRails.t("confirm_phone.pin_code")}
                </span>
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="column-flex">
            <_checkout_state_confirm_pin
              Rails={Rails}
              requirePhone={requirePhone}
              onChangePhoneNumberEvent={onChangePhoneNumberEvent}
              phone_number={phone_number}
              confirmPhoneNumber={confirmPhoneNumber}
              requirePinCode={requirePinCode}
              onChangePinEvent={onChangePinEvent}
              pinCode={pinCode}
              validatePinCode={validatePinCode}
              setUserPhone={setUserPhone}
              basket={basket}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        className="cart-state"
        disabled={basket.totals.numberOfCartItems < 1 ? true : false}
        expanded={order_state === "payment_options"}
      >
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("payment_options")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <PaymentIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.payment_options")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="column-flex">
          <_checkout_state_radios_payment
            basket={basket}
            radioValue={radioValue}
            onRadioChange={onRadioChange}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="cart-state"
        disabled={basket.totals.numberOfCartItems < 1 ? true : false}
        expanded={order_state === "payment"}
      >
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("payment")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <DescriptionIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.order_summary")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="column-flex">
          <_checkout_state_payment
            basket={basket}
            channel={channel}
            setBasketField={setBasketField}
            verifyVoucherCode={verifyVoucherCode}
            changeQuantity={changeQuantity}
            clearCart={clearCart}
            proceedToCheckout={proceedToCheckout}
            order={order}
            radioValue={radioValue}
            orderProcessing={orderProcessing}
            paypalProps={paypalProps}
            placeOrder={placeOrder}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
  // return pug`
  //   if !!props.component.state.errorMessage
  //     p.center.empty-cart
  //       b= props.component.state.errorMessage
  //   Accordion.cart-state(expanded=props.component.state.order_state === 'cart')
  //     AccordionSummary(onClick=(e) => props.component.gotoCheckoutState('cart') expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //       Typography
  //         span(style={display:'flex', alignItems:'center'})
  //           ShoppingCartIcon
  //           span(style={marginLeft:'16px'})
  //             = I18nRails.t('client.basket.your_order')
  //     AccordionDetails(className='column-flex', onClick=(e) => e.stopPropagation())
  //      ShowCart(hideCheckout=false, component=props.component)
  //   if !props.component.isLoggedIn()
  //     Accordion.cart-state(  expanded=props.component.state.order_state === 'register' )
  //       AccordionSummary(onClick=(e)=> props.component.gotoCheckoutState('register') expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //         Typography
  //           span(style={display:'flex', alignItems:'center'})
  //             AccountBoxIcon
  //             span(style={marginLeft:'16px'})
  //               = I18nRails.t('client.basket.your_order')
  //       AccordionDetails(className='column-flex', onClick=(e) => e.stopPropagation())
  //         _checkout_state_register(...props)
  //   Accordion.cart-state(  disabled=props.component.state.basket.totals.numberOfCartItems < 1? true : false,  expanded=props.component.state.order_state === 'channel')
  //     AccordionSummary(onClick=(e) => props.component.gotoCheckoutState('channel') expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //       Typography
  //         span(style={display:'flex', alignItems:'center'})
  //           ShoppingBasketIcon
  //           span(style={marginLeft:'16px'})
  //             = I18nRails.t('client.basket.checkout_mode')
  //     AccordionDetails(className='column-flex' onClick=(e) => e.stopPropagation())
  //       if props.component.state.basket.items.length > 0
  //         p.choose-channel ${ I18nRails.t('Web.ordering.choose_channel') }
  //         .checkoutDetail
  //           if props.component.state.basket.location && props.component.state.basket.location.channels
  //             each channel, index in props.component.state.basket.location.channels
  //               Button.clr-dark(style={marginRight:'1%'} key=index, onClick=(e) => props.component.selectChannel(channel.name)) ${ channel.name }
  //         if !!props.component.state.selectedDate
  //         LocalizationProvider(dateAdapter=AdapterDayjs)
  //           DateTimePicker(
  //             renderInput=${ (props) => <TextField {...props} /> },
  //             ampm,
  //             label="Want Order At",
  //             value=props.component.state.selectedDate,
  //             onChange=props.component.handleDateChange,
  //             disablePast,
  //             format="yyyy/MM/dd HH:mm a", className='mui-picket-custom-width'
  //           )
  //   if props.component.state && props.component.state.channel && props.component.state.channel === 'delivery'
  //     Accordion.cart-state( disabled=props.component.state.basket.totals.numberOfCartItems < 1? true : false , className=(props.component.state.channel === 'delivery' && user ? '': 'hide'), expanded=props.component.state.order_state == 'address')
  //       AccordionSummary(onClick=(e) => props.component.gotoCheckoutState('address') expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //         Typography
  //           span(style={display:'flex', alignItems:'center'})
  //             PlaceIcon
  //             span(style={marginLeft:'16px'})
  //               = I18nRails.t('client.basket.delivery_address')
  //       AccordionDetails(className='column-flex')
  //         _checkout_state_address(...props)
  //   // Accordion.cart-state( className=(props.component.isFutureOrderActivated() ? '' : 'hide'), expanded=props.component.state.order_state === 'delivery-time')
  //   //   AccordionSummary(onClick=(e) => props.component.gotoCheckoutState('delivery-time') expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //   //     Typography
  //   //       span(style={display:'flex', alignItems:'center'})
  //   //         PlaceIcon
  //   //         span(style={marginLeft:'16px'})
  //   //           | Delivery/Pickup Time
  //   //   AccordionDetails(className='column-flex')
  //   //     ${ <_checkout_state_delivery_time {...props.component.state} checkoutDayAhead={props.component.checkoutDayAhead} /> }
  //   if props.component.state && user && user.token_status == "set"
  //     Accordion.cart-state(disabled=props.component.state.basket.totals.numberOfCartItems < 1? true : false, className=((props.component.requirePinCode() || props.component.requirePhone()) ? '': 'hide'), expanded=props.component.state.order_state === 'confirm-pin')
  //       AccordionSummary(onClick=(e) => props.component.gotoCheckoutState('confirm-pin')  expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //         Typography
  //           span(style={display:'flex', alignItems:'center'})
  //             VerifiedUserIcon
  //             span(style={marginLeft:'16px'})
  //               = I18nRails.t('confirm_phone.pin_code')
  //       AccordionDetails(className='column-flex')
  //         _checkout_state_confirm_pin(...props)
  //   Accordion.cart-state( disabled=props.component.state.basket.totals.numberOfCartItems < 1? true : false, expanded=props.component.state.order_state === 'payment_options')
  //     AccordionSummary(onClick=(e) => props.component.gotoCheckoutState('payment_options') expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //       Typography
  //         span(style={display:'flex', alignItems:'center'})
  //           PaymentIcon
  //           span(style={marginLeft:'16px'})
  //             = I18nRails.t('client.basket.payment_options')
  //     AccordionDetails(className='column-flex')
  //       _checkout_state_radios_payment(...props)
  //   Accordion.cart-state(disabled=props.component.state.basket.totals.numberOfCartItems < 1? true : false, expanded=props.component.state.order_state === 'payment')
  //     AccordionSummary(onClick=(e) => props.component.gotoCheckoutState('payment')  expandIcon=${ <ExpandMoreIcon /> }, aria-controls='panel1a-content', id='panel1a-header')
  //       Typography
  //         span(style={display:'flex', alignItems:'center'})
  //           DescriptionIcon
  //           span(style={marginLeft:'16px'})
  //             = I18nRails.t('client.basket.order_summary')
  //     AccordionDetails(className='column-flex')
  //       _checkout_state_payment(...props)

  // `;
}
