import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { I18nRails } from "../../shared/rails-i18n-js";
import LocationReviewResource from "../resources/location-review";
import LocationLayout from "./LocationLayout";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import "react-medium-image-zoom/dist/styles.css";
import { date as dateFilter } from "../filters";
import { makeStyles } from "tss-react/mui";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import UserResource from "../resources/user";
import { withLocation } from "../../react-pos/components/withLocation";
import { withParams } from "../../react-pos/components/withParams";
import withReview from "./hoc/withReview";
import withParent from "./hoc/withParent";
import { isLoggedIn } from "./helpers/utils";

const styles = (theme) => ({
  root: {
    width: 100,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
});

const AppLocationReviews = ({
  Rails,
  fdLocationsReviews,
  showResourceError,
  toggleLike,
  enableEditing,
  editingFlag,
  location,
  setEditingFlag,
  cancelEditingReview,
  basket,
  fdLocationReviews,
  user,
  ...other
}) => {
  const [location_reviews, setLocation_reviews] = useState("");
  const [review_title, setReview_title] = useState("Location Reviews");

  const [values, setValues] = useState({
    reviewContent: "",
  });

  const [resource_id, setResource_id] = useState();
  const [editingReview, setEditingReview] = useState();
  const [editedReviewContent, setEditedReviewContent] = useState();
  const [location_id, setLocation_id] = useState();

  const createReview = (review) => {
    review = new LocationReview.current({
      location_id: resource_id,
      review,
    });
    review.$create(
      (success) => {
        var tempList = [];
        tempList = location_reviews;
        tempList.splice(1, 0, success);
        setLocation_reviews(tempList);
      },
      (error) => {
        showResourceError(error);
      }
    );
  };

  const editReview = () => {
    var review = new LocationReview.current({
      id: editingReview.id,
      content: editedReviewContent,
      location_id: resource_id,
    });
    review.$update(
      (success) => {
        var tempList = [];
        tempList = location_reviews;
        var index = tempList.indexOf(editingReview);
        if (index !== -1) {
          tempList[index] = success;
        }
        setLocation_reviews(tempList);
        setEditingFlag(false);
      },
      (error) => {
        showResourceError(error);
      }
    );
  };

  const initLocationReviews = (cb) => {
    if (!fdLocationReviews) {
      LocationReview.current.query(
        { location_id: location_id },
        (location_reviews) => {
          setLocation_reviews(location_reviews);
          console.log("location reviews,", location_reviews);
          if (!!cb) {
            return cb(location_reviews);
          }
        }
      );
    } else {
      const location_reviews = JSON.parse(fdLocationsReviews).map(
        (location_review) => new LocationReview.current(location_review)
      );
      setLocation_reviews(location_reviews);
      if (!!cb) {
        return cb(location_reviews);
      }
    }
  }

  const validateHandler = (values) => {
    const errors = {};

    if (!values.reviewContent) {
      errors.reviewContent = "content required";
    }

    return errors;
  };

  const onSubmit = (values, options) => {
    console.log("values", values);
    createReview({ content: values.reviewContent });
  };

  const useStyles = makeStyles()(styles)
  const {classes, cx} = useStyles();

  const children = function children({
    values,
    errors,
    touched,
    isSubmitting,
    isValidating,
  }) {
    return (
      <Form method="post" className="login-form">
        <Grid container>
          <Grid item xs={12}>
            <h5>
              {I18nRails.t("Web.write_a_review")}
              <Field
                name="reviewContent"
                placeholder={I18nRails.t("Web.help_others_review")}
                required
                type="text"
                component="input"
              />
              <ErrorMessage name="reviewContent" component="div" />
            </h5>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={
                Object.keys(errors).length || isSubmitting || isValidating
              }
              type="submit"
              className="clr-dark"
            >
              {I18nRails.t("Web.add_your_review")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
    // return pug`
    //   Form(method='post').login-form
    //     Grid(container)
    //       Grid(item xs=12)
    //         h5 ${ I18nRails.t('Web.write_a_review') }
    //           Field.browser-default(name='reviewContent', placeholder=I18nRails.t('Web.help_others_review'), required, type='text')
    //           ErrorMessage(name='reviewContent', component='validation-message')
    //       Grid(item xs=3)
    //         Button.clr-dark(disabled=Object.keys(errors).length || isSubmitting || isValidating, type='submit') ${ I18nRails.t(
    //   'Web.add_your_review'
    // ) }
    // `;
  };

  const LocationReview = useRef(LocationReviewResource.get(Rails));

  useEffect(() => {
    initLocationReviews();
  }, []);

  return (
    <LocationLayout
      Rails={ths.props.Rails}
      fndLocation={location}
      basket={basket}
      user={user}
      setLatLng={setLatLng}
      cartService={this.CartService}
      changeQuantity={this.props.changeQuantity}
      {...other}
    >
      <div className="tabular-content">
        {isLoggedIn(Rails) ? (
          <React.Fragment>
            {location_reviews.length < 1 ? (
              <main className="pad-right">
                {I18nRails.t("Web.be_the_first_review")}
              </main>
            ) : null}
            <Formik
              initialValues={values}
              validate={validateHandler}
              onSubmit={onSubmit}
            >
              {(formikProps) => children(formikProps)}
            </Formik>
          </React.Fragment>
        ) : (
          <main className="pad-right">
            {I18nRails.t("venue.alertmessage.togglereviewalert")}
          </main>
        )}
        {location_reviews.length > 0 && (
          <main className="pad-right">
            <h5>{review_title}</h5>
            {location_reviews.map((location_review) => (
              <List
                className="reviews-list"
                key={`location-review-${location_review.id}`}
              >
                <ListItem>
                  <Card elevation={3} style={{ width: "100%" }}>
                    <Grid container style={{ padding: "0.25%" }}>
                      {!!location_review.user && (
                        <>
                          <Grid
                            item
                            xs={2}
                            sm={1}
                            style={{
                              paddingRight: "0",
                              paddingLeft: "1%",
                              paddingTop: "1%",
                            }}
                          >
                            <Avatar
                              src={location_review.user.user_image_url}
                              style={{
                                height: "60px !important",
                                width: "60px !important",
                              }}
                            >
                              {location_review.user.first_name.charAt(0)}{" "}
                              {location_review.user.last_name.charAt(0)}
                            </Avatar>
                          </Grid>
                          <Grid
                            item
                            xs={location_review.user ? 8 : 12}
                            sm={location_review.user ? 9 : 12}
                            style={{ paddingLeft: "1%", paddingTop: "1%" }}
                          >
                            <span
                              style={{ fontWeight: "bold", marginTop: "1%" }}
                            >
                              {location_review.user &&
                                `${location_review.user.first_name} ${location_review.user.last_name}`}{" "}
                            </span>
                            {editingReview.id === location_review.id &&
                            editingFlag ? (
                              <Grid container>
                                <Grid item xs={7} sm={7}>
                                  <form noValidate autoComplete="off">
                                    <TextField
                                      id="standard-basic"
                                      label="Edit Review"
                                      value={editedReviewContent}
                                      onChange={(e) =>
                                        setEditedReviewContent(e.target.value)
                                      }
                                    />
                                  </form>
                                </Grid>
                                <Grid item xs={5} sm={5}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                      paddingTop: "5%",
                                      paddingBottom: "5%",
                                    }}
                                    onClick={(e) => editReview()}
                                  >
                                    Save Review
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      paddingTop: "5%",
                                      paddingBottom: "5%",
                                      marginLeft: "5%",
                                    }}
                                    onClick={(e) =>
                                      cancelEditingReview.current()
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                              </Grid>
                            ) : (
                              <p
                                style={{
                                  marginTop: "-8px",
                                  marginBottom: "3%",
                                }}
                              >
                                &quot;{location_review.content}&quot;
                                <span
                                  style={{
                                    fontSize: "10px",
                                    color: "grey",
                                    fontStyle: "italic",
                                  }}
                                >
                                  - at {dateFilter(location_review.created_at)}
                                </span>
                              </p>
                            )}
                          </Grid>
                        </>
                      )}
                      <Grid item xs={2} sm={2} style={{ paddingRight: "1%" }}>
                        {isLoggedIn(Rails) ? (
                          <a
                            className="right"
                            onClick={(e) =>
                              toggleLike.current(location_review, "Review", e)
                            }
                            style={{ padding: "4px" }}
                          >
                            <i
                              className={
                                "fas fa-heart " +
                                (isUserLiked(user, location_review, "Review")
                                  ? "red-text"
                                  : "")
                              }
                            ></i>
                            {location_review.favorites_count > 0
                              ? location_review.favorites_count
                              : ""}
                          </a>
                        ) : (
                          <a
                            className="right"
                            onClick={(e) =>
                              toggleLike.current(location_review, "Review", e)
                            }
                          >
                            <i className="fas fa-heart"></i>
                            {location_review.favorites_count > 0
                              ? location_review.favorites_count
                              : ""}
                          </a>
                        )}
                        {location_review.user.id == user.id && !editingFlag && (
                          <a
                            className="right"
                            onClick={(e) =>
                              enableEditing0.current(location_review, e)
                            }
                            style={{ padding: "4px" }}
                          >
                            <i className="fas fa-pencil-alt pencil-hover"></i>
                          </a>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </ListItem>
              </List>
            ))}
          </main>
        )}
      </div>
    </LocationLayout>
  );
  // return pug`
  //   LocationLayout(fndLocation=this.state.location, basket=this.state.basket, removeBasketItem=this.removeItem.bind(this), user=this.state.user, ...this.props)
  //     .tabular-content
  //       if this.isLoggedIn()
  //         if this.state.location_reviews.length < 1
  //           main.pad-right ${ I18nRails.t('Web.be_the_first_review') }
  //         Formik.personal-form(initialValues=this.state.values, validate=this.validateHandler, onSubmit=this.onSubmit)
  //           = this.children.bind(this)
  //       else
  //         if this.state.location_reviews.length < 1
  //           main.pad-right ${ I18nRails.t(
  //   'venue.alertmessage.togglereviewalert'
  // ) }
  //       if this.state.location_reviews.length > 0
  //         main.pad-right
  //           h5 ${ this.state.review_title }
  //         each location_review in this.state.location_reviews
  //           List.reviews-list
  //             ListItem(key='location-review-'+location_review.id)
  //               Card(elevation=3 style={width:'100%'})
  //                 Grid(container style={padding:'0.25%'})
  //                   if !!location_review.user
  //                     Grid(item xs=2 sm=1 style={paddingRight: '0', paddingLeft:'1%', paddingTop:'1%'})
  //                       Avatar(src=location_review.user.user_image_url style={height:'60px !important', width:'60px !important'}) ${ location_review.user.first_name.charAt(
  //   0
  // ) } ${ location_review.user.last_name.charAt(0) }
  //                     Grid(item xs=8 sm=9 style={paddingLeft:'1%', paddingTop:'1%'})
  //                       span(style={fontWeight:'bold', marginTop:'1%'}) ${ location_review.user.first_name
  //   } ${ location_review.user.last_name }
  //                       if this.state.editingReview.id == location_review.id && this.state.enableEditing
  //                         Grid(container)
  //                           Grid(item xs=7 sm=7 )
  //                             form(noValidate autoComplete="off")
  //                               TextField(id="standard-basic" label="Edit Review" value=${ this.state.editedReviewContent
  //   } onChange = (e)=> this.setState({editedReviewContent: e.target.value}))
  //                           Grid(item xs=5 sm=5 )
  //                             Button( variant="contained" color="secondary" style={paddingTop:'5%',paddingBottom:'5%'} onClick=(e)=>this.editReview()) Save Review
  //                             Button( variant="contained" color="primary" style={paddingTop:'5%',paddingBottom:'5%', marginLeft:'5%'} onClick=(e)=>this.cancelEditingReview()) Cancel
  //                       else
  //                         p(style={marginTop:'-8px', marginBottom:'3%'})
  //                           |"${ location_review.content }"
  //                           span(style={fontSize:'10px', color:'grey', fontStyle:'italic'}) - at ${ filters.date(
  //     location_review.created_at
  //   ) }
  //                   Grid(item xs=2 sm=2 style={paddingRight:'1%'})
  //                     if this.isLoggedIn()
  //                       a.right(onClick=(e) => this.toggleLike(location_review, "Review", e) style={padding:'4px'})
  //                         i.fas.fa-heart(className=(isUserLiked(user, location_review, "Review") ? "red-text" : ''))
  //                         = location_review.favorites_count > 0 ? location_review.favorites_count : ""
  //                       if location_review.user.id == user.id && !this.state.enableEditing
  //                         a.right(onClick=(e) => this.enableEditing(location_review,e) style={padding:'4px'})
  //                           i.fas.fa-pencil-alt(className="pencil-hover")
  //                     else
  //                       a.right(onClick=(e) => this.toggleLike(location_review, "Review", e))
  //                         i.fas.fa-heart
  //                         = location_review.favorites_count > 0 ? location_review.favorites_count : ""

  // `;
};

export default withParent(withReview(AppLocationReviews));
