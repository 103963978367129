import React from 'react';

import { I18nRails } from '../../../shared/rails-i18n-js';

import AddressesComponent from '../AddressesComponent';
import AddressComponent from '../AddressComponent';
import LocationResource from "../../resources/location";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import Grid from "@mui/material/Grid";
import { location_address } from '../../models/location';
import { isLoggedIn } from '../helpers/utils';

const template = ({
  Rails,
  basket,
  user,
  reselectAddress,
  selectAddress,
  cancelChannel,
  selectedAddress,
  unmappedAddress,
  showUpdatedAddressAlert,
  guestStreet1,
  guestStreet2,
  guestCity,
  guestZip,
  setGuestStreet1,
  setGuestStreet2,
  setGuestCity,
  setGuestZip,
  updateCustomerAddress
}) => {
  let latLng;
  if (!!basket && !!basket.location) {
    latLng = location_address(basket.location);
  } else {
    latLng = {};
  }
  return (
    isLoggedIn(Rails) ? (
      <Grid container>
        <AddressesComponent
          propsLatLng={latLng}
          propsOwner={user}
          reselectAddress={reselectAddress}
          selectAddress={selectAddress}
          cancelChannel={cancelChannel}
          propsSelectedAddress={selectedAddress}
          propsUnmappedAddress={unmappedAddress}
          Rails={Rails}
        />
      </Grid>
    ) : (
      <Grid container>
        <Grid item xs={8}>
          {!showUpdatedAddressAlert && (
            <Alert severity="error" style={{ marginBottom: '3%' }}>
              <AlertTitle>Delivery Not Available</AlertTitle>
              Sorry, this restaurant doesn't deliver here. Please update your address
            </Alert>
          )}
          <TextField
            label="Street 1"
            style={{ width: '100%' }}
            value={guestStreet1 || ''}
            type="text"
            required
            onChange={(e) => setGuestStreet1(e.target.value)}
          />
          <TextField
            label="Street 2"
            style={{ width: '100%' }}
            value={guestStreet2 || ''}
            type="text"
            required
            onChange={(e) => setGuestStreet2(e.target.value)}
          />
          <TextField
            label="City"
            style={{ width: '100%' }}
            value={guestCity || ''}
            type="text"
            required
            onChange={(e) => setGuestCity(e.target.value)}
          />
          <TextField
            label="Zip"
            style={{ width: '100%' }}
            value={guestZip || ''}
            type="text"
            required
            onChange={(e) => setGuestZip(e.target.value)}
          />
          <Button
            className="btn-second order-mode-btn"
            onClick={(e) => updateCustomerAddress(e)}
            style={{ marginTop: '3%' }}
          >
            Update Address
          </Button>
          {showUpdatedAddressAlert && (
            <Alert severity="success" style={{ marginTop: '3%' }}>
              <AlertTitle>Address Updated</AlertTitle>
              A 4-digit number was sent to your phone, please check!
            </Alert>
          )}
        </Grid>
      </Grid>
    )
  );
  
  // return pug`
  //   if props.component.isLoggedIn()
  //     Grid(container)
  //       AddressesComponent(latLng=latLng, owner=props.component.state.user, reselectAddress=props.component.reselectAddress, selectAddress=props.component.selectAddress, cancelChannel=props.component.cancelChannel, selectedAddress=props.component.state.selectedAddress, unmappedAddress=props.component.state.unmappedAddress, Rails=props.component.props.Rails)
  //       // Grid(item xs=4)
  //       //   if !!props.component.cancelChannel
  //       //     Button.btn-red(onClick = (e) => props.component.cancelChannel()) ${ I18nRails.t('Web.ordering.change_channel') }
  //   else
  //     Grid(container)
  //       Grid(item xs=8)
  //         if !props.component.state.showUpdatedAddressAlert
  //           ${ <Alert severity="error" style={{ marginBottom: '3%' }}>
  //     <AlertTitle>Delivery Not Available</AlertTitle>
  //     Sorry, props.component restaurant doesn't deliver here. Please update your address
  //   </Alert>
  //   }
  //         TextField(label='Street 1' style={width:'100%'}  value=props.component.state.guestStreet1 || '' type="text" required onChange=(e) => props.component.setState({guestStreet1: e.target.value}))
  //         TextField(label='Street 2' style={width:'100%'}  value=props.component.state.guestStreet2 || '' type="text" required onChange=(e) => props.component.setState({guestStreet2: e.target.value}))
  //         TextField(label='City' style={width:'100%'}  value=props.component.state.guestCity || '' type="text" required onChange=(e) => props.component.setState({guestCity: e.target.value}))
  //         TextField(label='Zip' style={width:'100%'}  value=props.component.state.guestZip || '' type="text" required onChange=(e) => props.component.setState({guestZip: e.target.value}))
  //         Button.btn-second.order-mode-btn(onClick = (e)=> props.component.updateCustomerAddress(e) style={marginTop:'3%'} ) Update Address
  //       if props.component.state.showUpdatedAddressAlert
  //         ${ <Alert severity="success" style={{ marginTop: '3%' }}>
  //     <AlertTitle>Address Updated</AlertTitle>
  //     A 4-digit number was sent to your phone, please check!
  //   </Alert>
  //   }



  // `;
};

export default template;
