import React, { Component, useRef } from "react";

import ReactTimeout from "react-timeout";

import { Link } from "react-router-dom";

import OrderComponent from "./OrderComponent";

import { I18nRails } from "../../shared/rails-i18n-js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {filterPhone} from "../filters";

import * as rh from "./helpers/routes";
import { withNavigate } from "../../react-pos/components/withNavigate";
import withParent from "./hoc/withParent";
import withOrder from "./hoc/withOrder";

const ShowOrders = ({
  Rails,
  orders,
  reorder,
  cancelOrder,
  ...other
}) => {
  return (
    <Table style={{marginTop: '6%'}}>
      <TableHead>
        <TableRow>
          <TableCell>{I18nRails.t("Web.restaurant")}</TableCell>
          <TableCell>{I18nRails.t("Web.number")}</TableCell>
          <TableCell>{I18nRails.t("Web.item")}</TableCell>
          <TableCell>{I18nRails.t("Web.status")}</TableCell>
          <TableCell>{I18nRails.t("Web.date")}</TableCell>
          <TableCell>{I18nRails.t("Web.price")}</TableCell>
          <TableCell>{I18nRails.t("Web.actions")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="light-blue lighten-5">
        {orders.map((order) => (
          <TableRow key={'hom-' + order.id}>
            <TableCell style={{width: '30%'}}>
              <div className="row">
                <div className="col l2">
                  <a href={'tel:' + filterPhone(order.location.phone)}>
                    <i className="green-text fas fa-2x fa-phone-square" />
                  </a>
                </div>
                <div className="col l10">
                  <Link className="btn-flat order-font" to={rh.show_location({location_id: order.location.slug})}>{order.location.name}</Link>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <Link to={rh.show_order({number: order.number})}>{order.number}</Link>
            </TableCell>
            <TableCell style={{width: '20%'}}>
              {order.order_items.map((order_item, index) => (
                order_item.item_type === "MenuItem" && (
                  <div key={'oi-' + index}>
                    <p className="order-txt2">{order_item.weighted_number}x {order_item.menu_item.title}</p>
                    {order_item.order_item_ingredients.map((order_item_ingredient, index_oii) => (
                      !!order_item_ingredient.menu_item_ingredient.customizable && (
                        <div className="chip green lighten-4 recent_order_ingredients" key={'oii-' + index_oii}>
                          <p className="order-txt2">{order_item_ingredient.number}x {order_item_ingredient.menu_item_ingredient.ingredient.name}</p>
                        </div>
                      )
                    ))}
                  </div>
                )
              ))}
            </TableCell>
            <TableCell style={{width: '10%'}}>
              {order.state === "received" && (
                <>
                  <div className="pending-order-acceptance">
                    <i className="fas fa-spinner fa-pulse" />
                  </div>
                  <div>{I18nRails.t('client.progress.waiting_for_restaurant_accept')}</div>
                </>
              )}
              {order.state === "cancelled" && (
                <div>{I18nRails.t('client.recents.order_cancelled')}</div>
              )}
              {order.state === "accepted" && (
                <div>{I18nRails.t('client.recents.order_accepted')}</div>
              )}
              {order.state === "ready" && (
                <div>{I18nRails.t('client.recents.order_ready')}</div>
              )}
              {order.isRejected() && (
                <>
                  {order.annotation ? (
                    <span>{I18nRails.t("activerecord.attributes.order.statuses.rejected")} : {order.annotation}</span>
                  ) : (
                    <span>{I18nRails.t("activerecord.attributes.order.statuses.rejected")}</span>
                  )}
                </>
              )}
              {order.isCompleted() && (
                <span>{I18nRails.t("activerecord.attributes.order.statuses.completed")}</span>
              )}
            </TableCell>
            <TableCell style={{width: '10%'}}>{I18nRails.l('date.formats.medium', order.received_at)}</TableCell>
            <TableCell style={{width: '10%'}}>
              {!!order.discount && order.discount > 0 ? (
                <>
                  <span className="discounted-actual">{I18nRails.numberToCurrency(order.actual_price, { unit: order.location.currency_symbol })}</span>
                  <span className="discount-final">{I18nRails.numberToCurrency(order.final_price, { unit: order.location.currency_symbol })}</span>
                </>
              ) : (
                <span>{I18nRails.numberToCurrency(order.final_price, { unit: order.location.currency_symbol })}</span>
              )}
            </TableCell>
            <TableCell className="reorder-btn" style={{ width: '10%' }}>
              <a
                className="order-btn"
                onClick={(e) => reorder(order)}
                style={{ cursor: 'pointer' }}
              >
                {I18nRails.t('client.progress.reorder')}
              </a>
              {!order.isCancelled() &&
                !order.isRejected() &&
                !order.isAccepted() &&
                !order.isCompleted() && <span>&nbsp;</span>}
              {!order.isCancelled() &&
                !order.isRejected() &&
                !order.isAccepted() &&
                !order.isCompleted() && (
                  <a
                    className="order-btn red"
                    onClick={(e) => cancelOrder(order)}
                    style={{ cursor: 'pointer' }}
                  >
                    {I18nRails.t('client.progress.cancel_order')}
                  </a>
                )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
  // return pug`
  //     Table(style={marginTop:'6%'})
  //       TableHead
  //         TableRow
  //           TableCell ${ I18nRails.t("Web.restaurant") }
  //           TableCell ${ I18nRails.t("Web.number") }
  //           TableCell ${ I18nRails.t("Web.item") }
  //           TableCell ${ I18nRails.t("Web.status") }
  //           TableCell ${ I18nRails.t("Web.date") }
  //           TableCell ${ I18nRails.t("Web.price") }
  //           TableCell ${ I18nRails.t("Web.actions") }
  //       TableBody.light-blue.lighten-5
  //         each order in this.props.orders
  //           TableRow(key='hom-'+order.id)
  //             TableCell(style={width: '30%'})
  //               .row
  //                 .col.l2
  //                   a(href='tel:'+filters.filterPhone(order.location.phone))
  //                     i.green-text.fas.fa-2x.fa-phone-square
  //                 .col.l10
  //                   Link.btn-flat.order-font(to=rh.show_location({location_id: order.location.slug})) ${ order.location.name
  //   }
  //             TableCell
  //               Link(to=rh.show_order({number: order.number}))= order.number
  //             TableCell(style={width: '20%'})
  //               each order_item, index in order.order_items
  //                 if order_item.item_type=="MenuItem"
  //                   div(key='oi-'+index)
  //                     p.order-txt2 ${ order_item.weighted_number }x ${ order_item.menu_item.title
  //   }
  //                     each order_item_ingredient, index_oii in order_item.order_item_ingredients
  //                       if !!order_item_ingredient.menu_item_ingredient.customizable
  //                         .chip.green.lighten-4.recent_order_ingredients(key='oii-'+index_oii)
  //                           .order-txt2 ${ order_item_ingredient.number }x ${ order_item_ingredient.menu_item_ingredient.ingredient.name
  //   }
  //             TableCell(style={width: '10%'})
  //               if order.state == "received"
  //                 .pending-order-acceptance
  //                   i.fas.fa-spinner.fa-pulse
  //                 div= I18nRails.t('client.progress.waiting_for_restaurant_accept')
  //               if order.state == "cancelled"
  //                 div= I18nRails.t('client.recents.order_cancelled')
  //               else if order.state == "accepted"
  //                 div= I18nRails.t('client.recents.order_accepted')
  //               else if order.state == "ready"
  //                 div= I18nRails.t('client.recents.order_ready')
  //               else if order.isRejected()
  //                 if order.annotation
  //                   span ${ I18nRails.t(
  //     "activerecord.attributes.order.statuses.rejected"
  //   ) }  : ${ order.annotation }
  //                 else 
  //                   span ${ I18nRails.t(
  //     "activerecord.attributes.order.statuses.rejected"
  //   ) } 
  //               else if order.isCompleted()
  //                 span ${ I18nRails.t(
  //     "activerecord.attributes.order.statuses.completed"
  //   ) } 
  //             TableCell(style={width: '10%'})= I18nRails.l('date.formats.medium', order.received_at)
  //             TableCell(style={width: '10%'})
  //               if !!order.discount && order.discount > 0
  //                 span
  //                   .discounted-actual ${ I18nRails.numberToCurrency(
  //     order.actual_price,
  //     { unit: order.location.currency_symbol }
  //   ) }
  //                   .discount-final ${ I18nRails.numberToCurrency(
  //     order.final_price,
  //     { unit: order.location.currency_symbol }
  //   ) }
  //               if order.discount <= 0
  //                 span ${ I18nRails.numberToCurrency(order.final_price, {
  //     unit: order.location.currency_symbol,
  //   }) }
  //             TableCell.reorder-btn(style={width: '10%'})
  //               a.order-btn(onClick=(e) => this.reorder(order), style={cursor: 'pointer'})= I18nRails.t('client.progress.reorder')
  //               if !order.isCancelled() && !order.isRejected() && !order.isAccepted() && !order.isCompleted()  
  //                 span  
  //               if !order.isCancelled() && !order.isRejected() && !order.isAccepted() && !order.isCompleted()
  //                 a.order-btn.red(onClick=(e) => this.cancelOrder(order), style={cursor: 'pointer'})= I18nRails.t('client.progress.cancel_order')

  // `;
};

export default withOrder(ShowOrders);
