import React, { useState, useRef, useEffect } from 'react';
import LocationLayout from './LocationLayout';
import InfiniteOrders from './InfiniteOrders';
import _loginOrSignup from './templates/_loginOrSignup';
import withParent from './hoc/withParent';
import withOrders from './hoc/withOrders';
import InfiniteItems from './InfiniteItems';

const AppLocationOrders = ({
  initLocation,
  listenForUpdates,
  cartService,
  doListenForUpdates,
  setDoListenForUpdates,
  infiniteOrdersRef,
  orderIndicesRef,
  user,
  ...other
}) => {
  const [location_id, setLocation_id] = useState();
  const [loadingOrders, setLoadingOrders] = useState();
  const [page0, setPage] = useState();
  const [per_page, setPer_page] = useState();
  const [location, setLocation] = useState();
  const [basket, setBasket] = useState();
  const [hasMore, setHasMore] = useState();

  const loadMoreOrders = () => {
    if (!!loadingOrders) { return; }
    const page = page0 + 1;
    setLoadingOrders(true);
    const Order = OrderResource.get(Rails);
    
    Order.query({}, { location_id: location_id, page, per_page: per_page }, orders => {
      let index = 0;
      orders = orders.filter(order => {
        let doSelect = true;
        if (typeof (orderIndicesRef.current[order.id]) === 'number') {
          //order already exists
          const stored_order = infiniteOrdersRef.current.items[orderIndicesRef.current[order.id]];
          if (order.id === stored_order.id) { //&& order.state != stored_order.state
            infiniteOrdersRef.current.items[orderIndicesRef.current[order.id]] = order;
          }
          doSelect = false;
        } else {
          //push order and record index
          orderIndicesRef.current[order.id] = infiniteOrdersRef.current.items.length + index;
        }

        index += 1;
        return doSelect;
      });
      const hasMore = orders.length > 0;
      infiniteOrdersRef.current.addItems(orders);
      setLoadingOrders(false);
      setPage(page);
      setHasMore(hasMore);
      setDoListenForUpdates(hasOpenOrders());
    });
  };

  const CartService = useRef(undefined);
  const config = {
    page: 0,
    per_page: 20,
    list_size: 1,
    filter: {},
    locale_country: Rails.locale_country
  };

  // TODO(react-declassify): refactor this effect (automatically generated from lifecycle)
  useEffect(() => {
    initLocation();
    listenForUpdates(location_id);
  }, []);

  return (
    <LocationLayout
      Rails={Rails}
      fndLocation={location}
      basket={basket}
      user={user}
      setLatLng={setLatLng}
      cartService={cartService}
      changeQuantity={changeQuantity}
      {...other}
    >
      <InfiniteOrders
        items={infiniteOrdersRef.current.items}
        loadMoreOrders={loadMoreOrders}
        loading={loadingOrders}
        hasMore={hasMore}
        {...other}
      />
    </LocationLayout>
  );

  // return pug`
  //   LocationLayout(fndLocation=this.state.location, basket=this.state.basket, removeBasketItem=this.removeItem.bind(this), user=this.state.user, ...this.props)
  //     InfiniteOrders(items=this.infiniteOrders.items, loadMoreOrders=this.loadMoreOrders.bind(this), loading=this.state.loadingOrders, hasMore=this.state.hasMore, ...this.props)
  // `;
};


export default withParent(withOrders(AppLocationOrders));
