import { calculateOrderItemPrice, orderItemAdjustQuantity, orderItemDisplayDescription, orderItemDisplayTitle, orderItemMatchesSelection, orderItemOptionValueDescription, orderItemOptionValueName } from '../models/order_item';
import FoodnerdResource from './FoodnerdResource';

export default class OrderItemResource {
  static get(Rails) {
    const base = `${ Rails.host }/api/order_items`;

    const OrderItem = new FoodnerdResource(Rails.access_token,
      `${ base }/{:id}.json`,
      { id: ':id' },
      {
        update: {
          method: 'PUT',
        },
      }
    );

    OrderItem.fromMenuItem = function(
      menu_item,
      option_value,
      menu_item_ingredients,
      quantity,
      location,
      menu
    ) {
      const order_item_ingredients = [];
      for (let selected_ingredient_key in menu_item_ingredients) {
        order_item_ingredients.push({
          menu_item_ingredient_id:
            menu_item_ingredients[selected_ingredient_key].id,
          menu_item_ingredient: menu_item_ingredients[selected_ingredient_key],
          weighted_number: menu_item_ingredients[selected_ingredient_key].count,
        });
      }

      const unit_price = calculateOrderItemPrice(
        menu_item,
        option_value,
        order_item_ingredients
      );
      if (!!option_value) {
        return new OrderItem({
          item_id: menu_item.id,
          item_type: 'MenuItem',
          menu_item_id: menu_item.id,
          menu_item,
          menu,
          unit_price,
          price: unit_price * quantity,
          type: 'OrderItem',
          component_option_value_id: option_value.component_option_value_id,
          component_option_value: option_value, //TODO need to change to option_value
          weighted_number: quantity,
          order_item_ingredients,
          location_id: location.id,
        });
      } else {
        return new OrderItem({
          item_id: menu_item.id,
          item_type: 'MenuItem',
          menu_item_id: menu_item.id,
          menu_item,
          menu,
          unit_price,
          price: unit_price * quantity,
          type: 'OrderItem',
          weighted_number: quantity,
          order_item_ingredients,
          location_id: location.id,
        });
      }
    };

    OrderItem.fromOrderItem = function(order_item) {
      //need to provide menu_item_id for order placement
      const order_item_new = new OrderItem(order_item);
      delete order_item_new.id;
      for (let order_item_ingredient of order_item_new.order_item_ingredients) {
        delete order_item_ingredient.id;
        delete order_item_ingredient.order_item_id;
      }

      return order_item_new;
    };


    OrderItem.prototype.optionValueName = function() {
      return orderItemOptionValueName(this);
    };

    OrderItem.prototype.optionValueDescription = function() {
      return orderItemOptionValueDescription(this);
    };

    OrderItem.prototype.displayTitle = function() {
      return orderItemDisplayTitle(this);
    };

    OrderItem.prototype.displayDescription = function() {
      return orderItemDisplayDescription(this);
    };

    OrderItem.prototype.matchesSelection = function(
      menu_item,
      component_option_value,
      order_item_ingredients
    ) {
      return orderItemMatchesSelection(this, menu_item, component_option_value, order_item_ingredients);
    };

    OrderItem.prototype.adjustQuantity = function(qty) {
      return orderItemAdjustQuantity(this, qty);
    };

    OrderItem.prototype.displayPrice = function() {
      return orderItemDisplayPrice(this);
    };

    return OrderItem;
  }
}
