import React, { Component, useState, useEffect } from "react";

import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";

import AddressResource from "../../resources/address";
import AddressComponent from "./AddressComponent";
import UserAddressResource from "../../resources/user-address";
import sweetalert2 from "sweetalert2";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import MapLocationPicker from "./Model/MapLocationPicker";
import { I18nRails } from "../../shared/rails-i18n-js";
import withParent from "./hoc/withParent";
import withAddresses from "./hoc/withAddresses";

const AddressesComponent = ({
  Rails,
  owner,
  selectedAddress,
  unmappedAddress,
  _unmappedAddress,
  selectAddress,
  addAddress, 
  cancelAddress,
  updateAddress,
  destroyAddress,
  toggleShowAddressEdit,
  isShowAddressEdit,
  saveMapAddress,
  showModal,
  cancelModal,
  onMarkerDragEnd
}) => {

  return <>
    <Grid container>
      {owner && owner.addresses && !(!!unmappedAddress && selectedAddress && unmappedAddress.id == selectedAddress.id) &&
        owner.addresses.map((address, index) => (
          <Grid xs={3} item key={`address${index}`}>
            <Tooltip title="Tap to select address">
              <Card
                elevation={7}
                style={{
                  height: "290px",
                  marginLeft: "1%",
                  marginRight: "2%",
                  marginTop: "2%",
                  marginBottom: "2%",
                  cursor: "pointer"
                }}
                className={
                  !!selectedAddress && selectedAddress.id == address.id
                    ? "selected white"
                    : "white"
                }
              >
                <CardActions>
                  {!!selectedAddress && selectedAddress.id == address.id && (
                    <i style={{ color: "#7CB07F" }} className="material-icons">
                      check_circle
                    </i>
                  )}
                  {isShowAddressEdit(address.id) && (
                    <Tooltip title="Cancel Editing">
                      <CloseIcon
                        onClick={(e) => toggleShowAddressEdit(address.id)}
                        style={{ color: "#76baff" }}
                      />
                    </Tooltip>
                  )}
                  {!isShowAddressEdit(address.id) && (
                    <div className="btn" onClick={(e) => toggleShowAddressEdit(address.id)}>
                      <Tooltip title="Edit Address">
                        <i style={{ color: "#76baff" }} className="material-icons">
                          edit
                        </i>
                      </Tooltip>
                    </div>
                  )}
                  <div className="btn" onClick={(e) => destroyAddress(address)}>
                    <Tooltip title="Delete">
                      <i style={{ color: "#FA6961" }} className="material-icons">
                        delete
                      </i>
                    </Tooltip>
                  </div>
                </CardActions>
                <Grid container>
                  <Grid item xs={12}>
                    { !isShowAddressEdit(address.id) ? (
                      <div className="address-selection-address" onClick={(e) => selectAddress(address)} style={{margin: "0"}}>
                        <div className="row person-name">
                          <p><b>{address.name_door}</b></p>
                        </div>
                        <div className="row address-1">
                          <p>{address.street}</p>
                        </div>
                        <div className="row address-2">
                          <p>{address.street2}</p>
                        </div>
                        <div className="row address-city">
                          <p>{address.city} {address.zip}</p>
                        </div>
                      </div>
                    ) : (
                      <AddressComponent 
                        address={address} 
                        addAddress={addAddress} 
                        cancelAddress={cancelAddress} 
                        updateAddress={updateAddress} 
                        Rails={Rails} />
                    )}
                  </Grid>
                </Grid>

              </Card>
            </Tooltip>
          </Grid>
        ))
      }
      { !!newAddress && (
        <div className="row choose-address">
          <AddressComponent
            address={{}}
            addAddress={addAddress}
            cancelAddress={cancelAddress}
            updateAddress={updateAddress}
            Rails={Rails}
          />
        </div>
      )}

      { !!unmappedAddress && (          
        <div>
          <Grid container>
            <Grid item xs={8}></Grid>
            <MapLocationPicker
              saveMapAddress={saveMapAddress}
              showModal={showModal}
              cancelModal={cancelModal}
            />
            <Grid item xs={12}>
              <Button 
                className="btn-second" 
                style={{marginBottom:'2%'}} 
                onClick={(e) => setShowModal(true)}
              >
                Open Map
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{marginTop:'2%'}}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </div>
      )}
      { !!_unmappedAddress && (
        <Grid container> 
          <Grid xs={12} item>
          </Grid>
        </Grid>
      )}          
    </Grid>
    <Grid container>
      { !!_unmappedAddress && (
        <>
          <Grid item xs={12}>
            <MapLocationPicker
              saveMapAddress={saveMapAddress}
              showModal={showModal}
              cancelModal={cancelModal}
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
              className="btn-second" 
              style={{marginBottom:'2%'}} 
              onClick={(e) => setShowModal(true)}
            >
              Open Map
              </Button>
          </Grid>
        </> 
      )}
      <Grid className="col-xs-4" style={{marginTop:'2%'}}>
        { !newAddress && (
          <Button className="btn-second order-mode-btn" style={{marginRight:'10px'}} onClick={(e) => enterNewAddress()}>{I18nRails.t('client.progress.addresses.new')}</Button>
        )}
      </Grid>
    </Grid>
  </>;

  // return pug`
  //   Grid(container)

  //     if this.state.owner && this.state.owner.addresses &&!(!!this.state.unmappedAddress && this.state.selectedAddress && this.state.unmappedAddress.id == this.state.selectedAddress.id)
  //       each address, index in this.state.owner.addresses  
  //         Grid(xs=3 item)
  //           Tooltip(title="Tap to select address")
  //             Card(elevation=7  key='address'+index, style={height:'290px', marginLeft:'1%', marginRight:'2%',marginTop:'2%', marginBottom:'2%',cursor: 'pointer'},className=!!this.state.selectedAddress && this.state.selectedAddress.id == address.id ? 'selected white' : 'white')   
  //               CardActions
  //                 if !!this.state.selectedAddress && this.state.selectedAddress.id ==  address.id
  //                   i(style={color:'#7CB07F'}).material-icons check_circle
  //                 if this.isShowAddressEdit(address.id)
  //                   Tooltip(title="Cancel Editing")
  //                     CloseIcon( onClick=(e) => this.toggleShowAddressEdit(address.id) style={color:'#76baff'})
  //                 if !this.isShowAddressEdit(address.id)
  //                   .btn(onClick=(e) => this.toggleShowAddressEdit(address.id))
  //                     Tooltip(title="Edit Address")
  //                       i(style={color:'#76baff'}).material-icons edit
  //                 .btn(onClick=(e) => this.destroyAddress(address))
  //                   Tooltip(title="Delete")
  //                     i(style={color:'#FA6961'}).material-icons delete     
  //               Grid(container)
  //                 Grid(item xs=12)
  //                   if !this.isShowAddressEdit(address.id)
  //                     .address-selection-address(onClick=(e) => this.props.selectAddress(address) style={margin:"0"})
  //                       .row.person-name
  //                         p
  //                           b ${ address.name_door }
  //                       .row.address-1
  //                         p ${ address.street }
  //                       .row.address-2
  //                         p ${ address.street2 }
  //                       .row.address-city
  //                         p ${ address.city } ${ address.zip }
  //                   else if this.isShowAddressEdit(address.id)
  //                     AddressComponent(address=address, addAddress=this.addAddress, cancelAddress=this.cancelAddress, updateAddress=this.updateAddress, Rails=this.Rails)

  //     if !!this.state.newAddress
  //       .row.choose-address
  //         AddressComponent(address={}, addAddress=this.addAddress, cancelAddress=this.cancelAddress, updateAddress=this.updateAddress, Rails=this.Rails)
  //     if !!this.state.unmappedAddress
  //       - var latLng = (!!this.state.latLng && !!this.state.latLng.lat && !!this.state.latLng.lng) ? this.state.latLng : this.state.unmappedAddress
  //       Grid(container)
  //         Grid(xs=8 item )          
  //         ${ (
  //     <MapLocationPicker
  //       saveMapAddress={this.saveMapAddress}
  //       showModal={this.state.showModal}
  //       cancelModal={this.cancelModal}
  //     />
  //   ) }
  //         Grid(item xs=12)
  //           Button.btn-second(style={marginBottom:'2%'} onClick=(e) => this.setState({showModal: true}))= 'Open Map'
  //             // Marker(opts={label: 'Identify Address', draggable: true, position: {lat: this.state.unmappedAddress.lat, lng: this.state.unmappedAddress.lng}},            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)})
  //             // | lat: #{this.state.unmappedAddress.lat}, lng: #{this.state.unmappedAddress.lng}
  //       Grid(container spacing=2 style={marginTop:'2%'})
  //         Grid(xs=3 item)
  //         Grid(xs=3 item)
  //           // Button.mobile-cancel-btn.btn-first.btn(onClick=(e) => this.cancelAddress(this.state.unmappedAddress))= I18nRails.t('client.progress.addresses.cancel')


  //     if !!this.state._unmappedAddress

  //       Grid(container)
  //         Grid(xs=12 item)
  //           // Button.mobile-cancel-btn.btn-first.btn(onClick=(e) => this.cancelAddress(this.state._unmappedAddress))= I18nRails.t('client.progress.addresses.cancel')

  //   Grid(container)
  //     if !!this.state._unmappedAddress
  //       - var latLng = (!!this.state.latLng && !!this.state.latLng.lat && !!this.state.latLng.lng) ? this.state.latLng : this.state._unmappedAddress
  //       Grid(item xs=12)
  //       ${ (
  //     <MapLocationPicker
  //       saveMapAddress={this.saveMapAddress}
  //       showModal={this.state.showModal}
  //       cancelModal={this.cancelModal}
  //     />
  //   ) }
  //       Grid(item xs=12)
  //         Button.btn-second(style={marginBottom:'2%'} onClick=(e) => this.setState({showModal: true}))= 'Open Map'
  //         // Button.btn-first.btn(onClick=(e) => this.cancelAddress(this.state._unmappedAddress))= I18nRails.t('client.progress.addresses.cancel')


  //     Grid(xs=4 item style={marginTop:'2%'})
  //       if !this.state.newAddress
  //         Button.btn-second.order-mode-btn(style={marginRight:'10px'} onClick=(e) => this.enterNewAddress())= I18nRails.t('client.progress.addresses.new')



  //         `;
};

export default withParent(withAddresses(AddressesComponent));